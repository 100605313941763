import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_2 = { class: "w-full my-3" }
const _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_CardDesign = _resolveComponent("CardDesign")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_DashboardHeader, {
          title: "SMS Dashboard",
          icon: _ctx.icon.dashboard
        }, null, 8, ["icon"])
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createVNode(_component_CardDesign, {
            data: {
            topic: 'SMS Sent',
            figures: _ctx.isLoading ? 'Loading...' : _ctx.smsStats.total_sent_sms,
            iconcolor: '#8280FF',
            icon: 'fa-solid fa-message',
            color: '#E5E4FF'
          }
          }, null, 8, ["data"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_CardDesign, {
            data: {
            topic: 'SMS Failed',
            figures: _ctx.isLoading ? 'Loading...' : _ctx.smsStats.total_failed_sms,
            iconcolor: '#FF9066',
            icon: 'fa-solid fa-comment-slash',
            color: '#FFDED1'
          }
          }, null, 8, ["data"])
        ])
      ])
    ])
  ], 64))
}