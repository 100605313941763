import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "sms-insights-chart grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mt-4" }
const _hoisted_2 = { class: "chart-container" }
const _hoisted_3 = {
  key: 0,
  class: "text-center text-sm py-2"
}
const _hoisted_4 = { class: "chart-container" }
const _hoisted_5 = {
  key: 0,
  class: "text-center text-sm py-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Loading..."))
        : (_openBlock(), _createBlock(_component_apexchart, {
            key: 1,
            options: _ctx.smsSentChartOptions,
            series: _ctx.smsSentChartData,
            type: "bar",
            height: "250"
          }, null, 8, ["options", "series"]))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Loading..."))
        : (_openBlock(), _createBlock(_component_apexchart, {
            key: 1,
            options: _ctx.smsFailedChartOptions,
            series: _ctx.smsFailedChartData,
            type: "bar",
            height: "250"
          }, null, 8, ["options", "series"]))
    ])
  ]))
}