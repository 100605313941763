<template>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
    <div class="chart-container" v-if="!isLoading">
      <apexchart
        v-if="networkTrafficChartData.length"
        :options="networkTrafficChartOptions"
        :series="networkTrafficChartData"
        type="area"
        height="350"
      />
      <div v-else class="text-center text-gray-500">No data available</div>
    </div>
    <div class="chart-container" v-else>
      <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
    </div>

    <div class="chart-container" v-if="!isLoading">
      <apexchart
        v-if="dataTransferRateChartData.length"
        :options="dataTransferRateChartOptions"
        :series="dataTransferRateChartData"
        type="bar"
        height="350"
      />
      <div v-else class="text-center text-gray-500">No data available</div>
    </div>
    <div class="chart-container" v-else>
      <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
    </div>

    <div class="chart-container" v-if="!isLoading">
      <apexchart
        v-if="packetLossChartData.length"
        :options="packetLossChartOptions"
        :series="packetLossChartData"
        type="radialBar"
        height="350"
      />
      <div v-else class="text-center text-gray-500">No data available</div>
    </div>
    <div class="chart-container" v-else>
      <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
    </div>

    <div class="chart-container" v-if="!isLoading">
      <apexchart
        v-if="latencyChartData.length"
        :options="latencyChartOptions"
        :series="latencyChartData"
        type="heatmap"
        height="350"
      />
      <div v-else class="text-center text-gray-500">No data available</div>
    </div>
    <div class="chart-container" v-else>
      <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
    </div>
  </div>
</template>

<script>
import ApexCharts from 'vue3-apexcharts';

export default {
  name: "NetworkAnalyticsChart",
  components: { apexchart: ApexCharts },
  props: {
    duration: { type: String, required: true },
    metricsData: { type: Object, required: true },
    isLoading: { type: Boolean, default: false }
  },
  data() {
    return {
      networkTrafficChartOptions: this.createChartOptions('Network Traffic (MB)', ['#A4C96F', '#58B2AE'], 'area'), // Green, Teal
      dataTransferRateChartOptions: this.createChartOptions('Data Transfer Rate (Mbps)', '#FF6B6B', 'bar'), // Coral
      packetLossChartOptions: this.createChartOptions('Packet Loss (%)', '#58B2AE', 'radialBar'), // Teal
      latencyChartOptions: this.createChartOptions('Latency (ms, Mock)', '#FFC94B', 'heatmap') // Yellow
    };
  },
  computed: {
    networkTrafficChartData() {
      const received = this.metricsData?.network_usage?.series[0]?.data || [];
      const transmitted = this.metricsData?.network_usage?.series[1]?.data || [];
      console.log('Network Traffic Chart Data:', {
        rawReceived: received,
        rawTransmitted: transmitted,
        series: [
          { name: 'Received', data: received },
          { name: 'Transmitted', data: transmitted }
        ]
      });
      return [
        { name: 'Received', data: received },
        { name: 'Transmitted', data: transmitted }
      ];
    },
    dataTransferRateChartData() {
      const downloadSpeeds = this.metricsData?.router_speed?.series[0]?.data || [];
      console.log('Data Transfer Rate Chart Data:', {
        rawData: downloadSpeeds,
        series: [{ name: 'Download Speed', data: downloadSpeeds }]
      });
      return [{ name: 'Download Speed', data: downloadSpeeds }];
    },
    packetLossChartData() {
      const mockLoss = [5.0]; // No packet loss data - mocked
      console.log('Packet Loss Chart Data:', {
        note: 'Mocked - no packet loss in API',
        series: mockLoss
      });
      return mockLoss;
    },
    latencyChartData() {
      const uploadSpeeds = this.metricsData?.router_speed?.series[1]?.data || [];
      const categories = this.getXaxisCategories();
      const heatmapData = uploadSpeeds.map((speed, index) => ({
        x: categories[index],
        y: Math.round(speed) // Using upload speed as proxy for latency (ms)
      }));
      console.log('Latency Chart Data:', {
        note: 'Mocked - using upload speeds as latency proxy',
        rawData: uploadSpeeds,
        series: [{ name: 'Latency', data: heatmapData }]
      });
      return [{ name: 'Latency', data: heatmapData }];
    }
  },
  watch: {
    duration: {
      immediate: true,
      handler() {
        this.updateChartOptions();
      }
    },
    metricsData: {
      immediate: true,
      handler(newData) {
        console.log('Metrics Data Updated:', {
          categories: newData?.categories,
          routerSpeed: newData?.router_speed,
          networkUsage: newData?.network_usage,
          signalStrength: newData?.router_signal_strength
        });
        this.updateChartOptions();
      }
    }
  },
  methods: {
    createChartOptions(title, color, chartType = 'bar') {
      const options = {
        chart: { type: chartType, height: 350 },
        title: { text: title, align: 'left' },
        xaxis: { categories: this.getXaxisCategories() },
        colors: Array.isArray(color) ? color : [color],
        plotOptions: chartType === 'radialBar' ? {
          radialBar: {
            hollow: { margin: 5 },
            dataLabels: {
              name: { show: true },
              value: { show: true, formatter: (val) => parseFloat(val).toFixed(2) }
            }
          }
        } : {}
      };
      if (chartType === 'radialBar') options.labels = ['Packet Loss'];
      return options;
    },
    getXaxisCategories() {
      return this.metricsData?.categories || [];
    },
    updateChartOptions() {
      const categories = this.getXaxisCategories();
      console.log('Updating Chart Options with Categories:', categories);
      this.networkTrafficChartOptions = { ...this.networkTrafficChartOptions, xaxis: { categories } };
      this.dataTransferRateChartOptions = { ...this.dataTransferRateChartOptions, xaxis: { categories } };
      this.packetLossChartOptions = { ...this.packetLossChartOptions, xaxis: { categories: ['Loss'] } };
      this.latencyChartOptions = { ...this.latencyChartOptions, xaxis: { categories } };
    }
  }
};
</script>


<style scoped>
.chart-container {
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.chart-container:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .chart-container {
    margin: 1rem 0;
  }
}
</style>




