<template>
  <DashboardHeader title="Your Profile" :icon="icon.accountIcon">
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span class="font-poppins font-medium text-[0.8rem] text-[#959CBD]"
          >Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span
        >
        <img
          src="../../assets/today.png"
          alt="africa"
          class="w-[0.8rem] h-[0.8rem] rounded ml-1"
        />
      </div>
    </template>
  </DashboardHeader>
  <div class="w-full my-3">
    <div class="lg:flex flex-row items-start gap-y-8 gap-x-8 mt-8 sm:gap-y-16">
      <div
        class="lg:mb-0 mb-6 lg:basis-2/6 relative overflow-x-auto pb-4 sm:mx-0 block sm:overflow-visible shadow-lg p-5 shadow-gray-900/5 bg-white rounded-2xl"
      >
        <div class="lg:mb-0 mb-6">
          <h3 class="font-medium mb-4">Your Profile</h3>
          <div
            class="relative px-3 mb-1 py-3 listhover rounded-md"
            :class="[activeTab == 'profile' ? 'bg-white ' : '']"
          >
            <img
              class="inline-block"
              src="../../assets/user-profile.svg"
              alt=""
            />
            <div class="relative inline-block">
              <div class="text-md text-slate-500">
                <button
                  @click="handleSetActiveTab('profile')"
                  class="text-[#464E5F] ml-3 font-medium"
                  role="tab"
                  type="button"
                >
                  Your Profile
                </button>
              </div>
            </div>
          </div>
          <div
            class="relative px-3 mb-1 py-3 listhover rounded-md"
            :class="[activeTab == 'changepassword' ? 'bg-white ' : '']"
          >
            <img class="inline-block" src="../../assets/lock.svg" alt="" />
            <div class="relative inline-block">
              <div class="text-md text-slate-500">
                <button
                  @click="handleSetActiveTab('changepassword')"
                  class="text-[#464E5F] ml-3 font-medium"
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
          <div class="relative px-3 mb-1 py-3 listhover rounded-md">
            <img class="inline-block" src="../../assets/sign-in.svg" alt="" />
            <div class="relative inline-block">
              <div @click="handleLogout" class="text-md text-blue-600">
                <button class="text-[#464E5F] ml-3 font-medium">Logout</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="lg:col-span-3 asis-1/2 full-width"
        :class="[activeTab == 'profile' ? 'block ' : 'hidden']"
      >
        <form @submit.prevent="submitForm">
          <div
            class="profile-sec full-width shadow-lg p-6 shadow-gray-900/5 bg-white rounded-2xl full-width"
          >
            <div class="profile-sec">
              <h3
                class="text-left mb-4 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor"
              >
                Profile
              </h3>
              <div class="lg:gap-4 gap-2 grid grid-cols-1 lg:grid-cols-2">
                <div class="form-group">
                  <label
                    class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                  >
                    First Name
                  </label>
                  <input
                    type="name"
                    name="firstName"
                    class="mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                    placeholder="Enter First Name"
                    v-model="firstName"
                  />
                </div>
                <div class="form-group">
                  <label
                    class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                  >
                    Last Name
                  </label>
                  <input
                    type="name"
                    name="lastName"
                    class="mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                    placeholder="Enter Your LastName"
                    v-model="lastName"
                  />
                </div>
                <div class="form-group">
                  <label
                    class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                  >
                    Phone
                  </label>
                  <input
                    type="name"
                    name="phone"
                    class="mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                    placeholder="Enter Phone "
                    v-model="phone"
                  />
                </div>
                <div class="form-group">
                  <label
                    class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                  >
                    Gender
                  </label>
                  <select
                    id="gender"
                    type="name"
                    class="text-[14px] w-full h-[2.688rem] border-[0rem] bg-[#f3f6f9] rounded-[0.4rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                    v-model="gender"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div class="form-group">
                  <div v-if="!newProfilePicture">
                    <label
                      class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                    >
                      Profile Picture
                    </label>
                    <img
                      v-if="profilePicture"
                      :src="profilePicture"
                      alt="profile picture"
                      class="mb-5 object-cover w-[200px] h-[200px]"
                    />
                  </div>
                  <div v-if="newProfilePicture">
                    <label
                      class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                    >
                      New Profile Picture
                    </label>
                    <p class="text-xs text-primary mb-2">
                      Click on Save Changes to apply the image
                    </p>
                    <img
                      :src="newProfilePicture"
                      alt="new profile picture"
                      class="mb-5 object-cover w-[200px] h-[200px]"
                    />
                  </div>
                  <input
                    type="file"
                    ref="profilePicture"
                    @change="onFileChange"
                    class="mt-1 font-semibold px-6 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  />
                </div>
                <div class="form-group">
                  <label
                    class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                  >
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    name="dob"
                    class="mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                    placeholder="Enter Your Date of birth"
                    v-model="date_of_birth"
                  />
                </div>
              </div>

              <div class="sm:text-right mt-4">
                <button
                  v-if="!updatingProfile"
                  class="bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium mr-2 border"
                >
                  Save Changes
                </button>
                <button
                  disabled
                  v-else
                  class="bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium mr-2 border"
                >
                  Updating...
                </button>
              </div>
            </div>

            <div
              class="email-sec mt-8 full-width"
              :class="[activeTab == 'changepassword' ? 'd-block ' : 'd-none']"
            >
              <h3
                class="text-left mb-4 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor"
              >
                Change Email Address
              </h3>
              <div class="form-group">
                <label
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  readonly
                  class="mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  placeholder="Enter Email Address"
                  v-model="email"
                />
              </div>

              <div class="sm:text-right mt-4">
                <button
                  type="submit"
                  class="bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium mr-2 border"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div
        class="w-full"
        :class="[activeTab == 'changepassword' ? 'block ' : 'hidden']"
      >
        <form @submit.prevent="changePasswordForm">
          <div
            class="profile-sec shadow-lg p-6 shadow-gray-900/5 bg-white rounded-2xl full-width"
          >
            <h3
              class="text-left mb-4 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor"
            >
              Change Password
            </h3>

            <div
              class="gap-x-4 gap-y-4 grid grid-cols-1 gap-x-4 xl:grid-cols-3"
            >
              <div class="form-group">
                <label
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                >
                  Existing Password
                </label>
                <input
                  type="password"
                  name="old_password"
                  class="mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  v-model="old_password"
                  placeholder=""
                />
              </div>

              <div class="form-group">
                <label
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                >
                  New Password
                </label>
                <input
                  type="password"
                  name="new_password"
                  class="mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  v-model="new_password"
                  placeholder=""
                />
              </div>

              <div class="form-group">
                <label
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  name="confirm_password"
                  class="mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  v-model="confirm_password"
                  placeholder=""
                />
                <p v-if="confirmPasswordError" class="error">
                  {{ confirmPasswordError }}
                </p>
              </div>
            </div>
            <div class="sm:text-right mt-6">
              <button
                type="submit"
                class="bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium mr-2 border"
              >
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapMutations } from 'vuex';
import { defineComponent, ref } from 'vue';

import moment from 'moment';
import router from '@/router';
import axios from '@/axios-interceptor';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import { dashboardHeadericon } from '../../helpers/stub_data/dashboard_routers';

export default defineComponent({
  name: 'account-info',
  components: {
    DashboardHeader,
  },
  setup() {
    const icon = ref({ ...dashboardHeadericon });
    const activeId = ref(1);
    const activeTab = ref('profile');
    const center = ref({ lat: -1.8899577, lng: 30.0634073 });
    const markers = ref([
      {
        position: { lat: -1.8899577, lng: 30.0634073 },
      },
    ]);
    const isOpenModal = ref(false);
    return {
      activeId,
      center,
      markers,
      isOpenModal,
      activeTab,
      icon,
    };
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      gender: '',
      phone: '',
      email: '',
      date_of_birth: '',
      new_password: '',
      old_password: '',
      confirm_password: '',
      confirmPasswordError: '',
      profilePicture: '' as any,
      newProfilePicture: '' as any,
      updatingProfile: false,
    };
  },
  computed: {
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
  },
  methods: {
    ...mapMutations(['setAuthenticated']),
    handleLogout() {
      this.setAuthenticated();
      localStorage.clear();
      router.push({ path: '/' });
    },
    onFileChange() {
      const fileInput = this.$refs.profilePicture as any;
      const file = fileInput.files[0];
      this.newProfilePicture = URL.createObjectURL(file);
    },
    handleSetActive(id: number) {
      this.activeId = id;
    },
    handleSetActiveTab(name: string) {
      this.activeTab = name;
    },
    getDate(date: Date | number) {
      return moment(date).calendar();
    },
    handleOpenOrCloseModal() {
      this.isOpenModal = !this.isOpenModal;
    },
    handleCloseModal() {
      if (this.isOpenModal) {
        this.isOpenModal = false;
      }
    },
    async submitForm() {
      this.updatingProfile = true;

      const fileInput = this.$refs.profilePicture as any;
      const file = fileInput.files[0];

      const formData = new FormData();

      formData.append('first_name', this.firstName);
      formData.append('last_name', this.lastName);
      formData.append('email', this.email);
      formData.append('phone', this.phone);
      formData.append('gender', this.gender);

      if (this.date_of_birth) {
        formData.append('date_of_birth', this.date_of_birth);
      }

      if (file) {
        formData.append('profile_pic', file);
      }

      try {
        const response = await axios.patch('auth/users/me/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        this.updatingProfile = false;

        this.$toast.success('Profile updated successfully', {
          position: 'top-right',
        });

        this.$store.commit('setLoggedInUser', response.data);

        this.newProfilePicture = '';

        this.firstName = response.data.first_name;
        this.lastName = response.data.last_name;
        this.email = response.data.email;
        this.phone = response.data.phone;
        this.gender = response.data.gender;
        this.date_of_birth = response.data.date_of_birth;
        this.profilePicture = response.data.profile_pic;
      } catch (error) {
        this.updatingProfile = false;
        console.error(error);
        this.$toast.error('Could not update the profile', {
          position: 'top-right',
        });
      }
    },

    changePasswordForm() {
      if (this.new_password === this.confirm_password) {
        this.confirmPasswordError = '';
        this.changePassword();
      } else {
        this.confirmPasswordError = 'Passwords do not match';
      }
    },
    async changePassword() {
      const formData = {
        new_password: this.new_password,
        current_password: this.old_password,
      };
      try {
        const response = await axios.post('auth/users/set_password/', formData);
        if (response) {
          this.$toast.success('Password reset has been sent to your inbox.', {
            position: 'top-right',
          });
        }
      } catch (error: any) {
        console.log(error);
      }
    },
    getuserData() {
      axios
        .get(`auth/users/me/`)
        .then((response: { data: any }) => {
          console.log('getuserData API Response:', response.data);
          this.firstName = response.data.first_name;
          this.lastName = response.data.last_name;
          this.email = response.data.email;
          this.phone = response.data.phone;
          this.gender = response.data.gender;
          this.date_of_birth = response.data.date_of_birth;
          this.profilePicture = response.data.profile_pic;
        })
        .catch((error: any) => {
          console.error(error);
        });
    },
  },
  onSetActiveg() {
    this.$emit('click');
  },
  mounted() {
    this.getuserData();
  },
});
</script>
