<template>
  <section>
    <div class="rounded-xl p-6 bg-gray-100 bg-white my-6">
      <h5 class="mb-4 text-base font-bold">Router Management Summary</h5>
      <div class="grid grid-cols-4 gap-6">
        <CardDesign :data="{
          topic: 'Tokens Generated',
          icon: 'fa-solid fa-chart-line',
          figures: loadingOne || loadingTwo ? 'Loading...' : generatedTokens,
          color: '#D9F7E8',
          iconcolor: '#00B69B',
        }" />
        <CardDesign :data="{
          topic: 'Tokens Used',
          icon: 'fa-solid fa-users',
          figures: loadingOne || loadingTwo ? 'Loading...' : usedTokens,
          color: '#E5E4FF',
          iconcolor: '#8280FF',
        }" />
        <CardDesign :data="{
          topic: 'Currently Connected',
          icon: 'fa-solid fa-gem',
          figures: loadingOne || loadingTwo ? 'Loading...' : connectedUsers,
          color: '#FFF3D6',
          iconcolor: '#FEC53D',
        }" />
        <CardDesign :data="{
          topic: 'Average Duration',
          icon: 'fa-solid fa-hourglass-half',
          figures: loadingOne || loadingTwo ? 'Loading...' : averageDuration,
          iconcolor: '#FF9066',
          color: '#FFDED1'
        }" />
        <CardDesign v-if="source === 'analytics-page'" :data="{
          topic: 'EXPIRED TOKENS',
          icon: 'fa-solid fa-clock-rotate-left',
          figures: loadingOne || loadingTwo ? 'Loading...' : expiredTokens,
          color: '#FEE7EC',
          iconcolor: '#F93C65',
        }" />
        <CardDesign v-if="source === 'analytics-page'" :data="{
          topic: 'TOKENS FAILED',
          icon: 'fa-solid fa-bug',
          figures: loadingOne || loadingTwo ? 'Loading...' : failedTokens,
          color: '#FEE7EC',
          iconcolor: '#F93C65',
        }" />
      </div>
    </div>

    <div v-if="source !== 'homepage'" class="grid grid-cols-2 gap-6">
      <div v-for="(chart, index) in charts" :key="index" class="chart-container">
        <div v-if="loadingTwo" class="text-center text-sm py-2">Loading charts...</div>
        <apexchart
          v-else
          ref="charts"
          height="250"
          width="400"
          :options="chart.options"
          :series="chart.series"
        ></apexchart>
      </div>
    </div>
  </section>
</template>

<script>
import ApexCharts from 'vue3-apexcharts';
import CardDesign from "@/components/Common/CardDesign.vue";

export default {
  name: "TokenAnalyticsChart",
  components: {
    apexchart: ApexCharts,
    CardDesign,
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    source: {
      type: String,
      required: true
    },
    currentTimeRange: {
      type: String,
      default: '3months'
    },
    loadingOne: {
      type: Boolean,
      default: false
    },
    loadingTwo: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      usedTokens: null,
      generatedTokens: null,
      connectedUsers: null,
      averageDuration: null,
      failedTokens: null,
      expiredTokens: null,
      selectedDuration: this.currentTimeRange,
      charts: [
        {
          title: 'Tokens Used and Currently Connected',
          options: this.getChartOptions(),
          series: [],
        },
        {
          title: 'Tokens Generated and Expired Tokens',
          options: this.getChartOptions(),
          series: [],
        },
        {
          title: 'Failed Tokens and Average Duration',
          options: this.getChartOptions(true),
          series: [],
        }
      ]
    };
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newData) {
        console.log("TOKEN ANALYTICS CHARTS - DATA CHANGED: ", newData);
        console.log("TOKEN ANALYTICS CHARTS - SELECTED DURATION: ", this.currentTimeRange);

        if (newData && this.currentTimeRange) {
          console.log("TOKEN ANALYTICS CHARTS - CURRENT TIME RANGE CHANGE: ", this.currentTimeRange);
          console.log("TOKEN ANALYTICS CHARTS - CURRENT TIME RANGE NEW DATA: ", newData[this.currentTimeRange]);

          if (this.loadingTwo) {
            this.resetCharts();
          } else {
            this.updateCharts(newData[this.currentTimeRange]);
          }
        }
      }
    },
    loadingTwo: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.resetCharts();
        } else if (this.data && this.data[this.selectedDuration]) {
          this.updateCharts(this.data[this.selectedDuration]);
          this.$nextTick(() => {
            this.refreshCharts();
          });
        }
      }
    },
    currentTimeRange(newValue) {
      this.selectedDuration = newValue;
      if (!this.loadingTwo && this.data && this.data[newValue]) {
        this.updateCharts(this.data[newValue]);
        this.$nextTick(() => {
          this.refreshCharts();
        });
      }
    }
  },
  methods: {
    getChartOptions(includeDurationAxis) {
      let options = {
        chart: {
          type: 'area',
          height: 250
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'category',
          categories: [],
          labels: {
            formatter: (value) => this.formatXAxisLabel(value),
            rotate: 0,
            rotateAlways: false,
            hideOverlappingLabels: true,
          }
        },
        yaxis: [
          {
            title: {
              text: 'Count'
            }
          }
        ],
        tooltip: {
          y: {
            formatter: function (val, { seriesIndex, dataPointIndex, w }) {
              if (w.config.series[seriesIndex].name === 'Average Duration (minutes)') {
                return val.toFixed(2) + ' minutes';
              }
              return val.toFixed(0);
            }
          }
        }
      };

      if (includeDurationAxis) {
        options.yaxis.push({
          opposite: true,
          title: {
            text: 'Duration (minutes)'
          },
          decimalsInFloat: 2
        });
      }

      return options;
    },

    formatXAxisLabel(value) {
      switch (this.selectedDuration) {
        case 'today':
          return value;
        case '1week':
          return value;
        case '1month':
          return value;
        case '3months':
        case '6months':
        case '12months':
        case '5years':
          return value;
        default:
          return value;
      }
    },

    updateCharts(data) {
      if (this.loadingTwo) {
        this.resetCharts();
        return;
      }

      // Update totals
      this.usedTokens = data.series[0].total;
      this.connectedUsers = data.series[1].total;
      this.generatedTokens = data.series[2].total;
      this.averageDuration = data.series[3].total;
      this.failedTokens = data.series[4].total;
      this.expiredTokens = data.series[5].total;

      this.charts.forEach((chart, index) => {
        chart.options.xaxis.categories = data.categories;

        if (index === 2) {
          chart.series = [
            { name: 'Failed Tokens', data: data.series[4].data },
            {
              name: 'Average Duration (minutes)',
              data: data.series[3].data.map(duration => {
                let totalMinutes = 0;

                if (duration.includes("day")) {
                  const [daysPart, timePart] = duration.split(", ");
                  const days = parseInt(daysPart.split(" ")[0]);
                  const [hours, minutes, seconds] = timePart.split(':').map(Number);
                  totalMinutes = (days * 24 * 60) + (hours * 60) + minutes + (seconds / 60);
                } else {
                  const [hours, minutes, seconds] = duration.split(':').map(Number);
                  totalMinutes = (hours * 60) + minutes + (seconds / 60);
                }

                return Number(totalMinutes.toFixed(2));
              })
            }
          ];
        } else {
          const seriesData = [
            index === 0 ? data.series[0].data : data.series[2].data,
            index === 0 ? data.series[1].data : data.series[5].data
          ];

          chart.series = [
            { name: chart.title.split(' and ')[0], data: seriesData[0] },
            { name: chart.title.split(' and ')[1], data: seriesData[1] }
          ];
        }
      });
    },

    resetCharts() {
      this.charts.forEach(chart => {
        chart.series = [];
        chart.options.xaxis.categories = [];
      });
    },

    refreshCharts() {
      if (this.$refs.charts) {
        this.$refs.charts.forEach(chart => {
          if (chart) {
            chart.updateOptions(this.charts[this.$refs.charts.indexOf(chart)].options);
            chart.updateSeries(this.charts[this.$refs.charts.indexOf(chart)].series);
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.chart-container {
  margin-bottom: 30px;
  transition: transform 0.3s ease;
  background: white;
  border-radius: 0.5rem;
  padding: 1rem;
}

.chart-container:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .chart-container {
    margin: 1rem 0;
  }
}
</style>