<template>
  <div style="display: flex; justify-content: space-between;">
    <div>
      <DashboardHeader title="SMS Dashboard" :icon="icon.dashboard"></DashboardHeader>
    </div>
  </div>

  <div class="w-full my-3">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 pt-2">
      <!-- SMS Sent Card -->
      <div>
        <CardDesign
          :data="{
            topic: 'SMS Sent',
            figures: isLoading ? 'Loading...' : smsStats.total_sent_sms,
            iconcolor: '#8280FF',
            icon: 'fa-solid fa-message',
            color: '#E5E4FF'
          }"
        />
      </div>

      <!-- SMS Failed Card -->
      <div>
        <CardDesign
          :data="{
            topic: 'SMS Failed',
            figures: isLoading ? 'Loading...' : smsStats.total_failed_sms,
            iconcolor: '#FF9066',
            icon: 'fa-solid fa-comment-slash',
            color: '#FFDED1'
          }"
        />
      </div>
    </div>
  </div>
</template>
  
  <script lang="ts">
  import { defineComponent, ref, PropType } from 'vue';
  import DashboardHeader from '../../components/markup/DashboardHeader.vue';
  import CardDesign from "../../components/Common/CardDesign.vue";
  import { dashboardHeadericon } from '../../helpers/stub_data/dashboard_routers';
  import { mapGetters } from "vuex";
  
  interface SMSAnalyticsData {
    sent_sms: { [key: string]: number };
    failed_sms: { [key: string]: number };
  }
  
  interface SMSStats {
    total_sent_sms: number;
    total_failed_sms: number;
  }
  
  export default defineComponent({
    name: 'SMSPage',
    components: {
      DashboardHeader,
      CardDesign,
    },
    props: {
      smsData: {
        type: Object as PropType<SMSAnalyticsData>,
        default: () => ({ sent_sms: {}, failed_sms: {} }), // Default instead of null
      },
      smsStats: {
        type: Object as PropType<SMSStats>,
        required: true,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters(["loggedInUser"]),
    },
    setup() {
      const icon = ref({ ...dashboardHeadericon });
      return { icon };
    },
  });
  </script>
<style scoped>
/* Existing Styles */
.card {
    display: flex;
    justify-content: space-between;
}

.card .card-holder {
    width: 70%;
}

.check {
    color: #006665;
    margin-left: 50px;
}

.info {
    color: red;
    margin-left: 50px;
}

.name {
    font-weight: bold;
}

.sms-cont {
    box-shadow: 1px 1px 10px 1px rgb(219, 219, 219);
    margin-top: 3%;
    padding: 20px;
}

select {
    width: 150px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

/* New Styles */
.chart-container {
    background-color: #ffffff;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.chart-container:hover {
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .chart-container {
        margin: 1rem 0;
    }
}
</style>
