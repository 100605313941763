<!-- LoginOtp.vue -->
<template>
  <div class="w-full lg:flex justify-between">
    <div class="lg:w-[50%]">
      <div class="flex flex-col justify-center items-center h-full relative">
        <div class="max-form w-full lg:py-0 py-10">
          <img src="../../assets/ared-logo.svg" class="w-[8rem] lg:hidden block m-auto mb-5" alt="right view" />
          <div class="bg-white rounded-[0.432rem] lg:p-0 p-4">
            <h1 class="font-['poppins'] font-bold text-[26px] text-center">
              OTP Sent!
            </h1>
            <p class="font-poppins font-normal text-[18px] mt-2 text-[#4E8D6E] text-center">
              Enter the One Time Password token sent to your email.
            </p>
            <form class="mt-10" @submit.prevent="handleLogin">
              <TextField
                name="otp"
                class="mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                placeholder="OTP"
                type="text"
                v-model="v$.otp.$model"
                :showErrors="v$.otp.$errors.length ? true : false"
                :errors="v$.otp.$errors"
              />
              <div class="w-full flex lg:justify-end justify-start items-center -mt-3 mb-6">
                <button type="button" @click="handleResend">
                  <span class="font-poppins font-medium text-[0.778rem] lg:text-[#4D99FF] text-[#4E8D6E]">Resend OTP</span>
                </button>
              </div>
              <button
                v-if="!loading"
                :disabled="v$.$invalid"
                type="submit"
                class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium"
              >
                Confirm
              </button>
              <button
                v-else
                type="button"
                class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium"
              >
                Processing...
              </button>
            </form>
          </div>
        </div>

        <!-- Terms Popup -->
        <div v-if="showAgreement" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div class="bg-white rounded-lg p-6 w-full max-w-md max-h-[80vh] flex flex-col">
            <div class="flex justify-center mb-4">
              <img src="../../assets/ared-logo.svg" alt="ARED Main Logo" class="w-[150px]" />
            </div>
            <div ref="scrollDiv" @scroll="handleScroll" class="max-h-[400px] overflow-y-auto mb-4 px-2 text-sm">
              <h2 class="text-lg font-bold">ARED MASTER SUBSCRIPTION AGREEMENT (MSA)</h2>
              <p class="mt-2"><strong>Effective Date:</strong> 03/24/2025</p>
              <p class="mt-2"><strong>Version:</strong> 1.0</p>
              <p class="mt-2">
                This Master Subscription Agreement ("Agreement") is entered into by and between ARED Rwanda Ltd ("ARED", "we", "us", or "our"), with registered offices at East Wing, KG 7 Ave, Kigali Heights, 4th Floor, Kigali, Rwanda, and the customer or entity accepting this Agreement ("Customer", "you", or "your"). By creating an account, logging into the platform, or activating any ARED service, you agree to be bound by the terms of this Agreement.
              </p>

              <h3 class="mt-4 font-semibold">1. SERVICES OVERVIEW</h3>
              <p>1.1 ARED provides edge-based software-as-a-service (SaaS) applications hosted on ARED Edge Gateways.</p>
              <p>1.2 Services include (but are not limited to) business management tools, security camera systems, feedback systems, queuing tools, AI assistance, IoT data processing, and other modular applications.</p>
              <p>1.3 Customers may activate or deactivate services through the ARED Dashboard at their discretion. All usage is governed by this Agreement regardless of activation date.</p>
              <p>1.4 ARED’s services may support business-to-business (B2B) environments in which the Customer uses the services to serve end users. The Customer is responsible for ensuring their use of ARED services complies with applicable laws and their own end user policies.</p>

              <h3 class="mt-4 font-semibold">2. ACCOUNT & ACCESS</h3>
              <p>2.1 You must register and maintain an active account to access ARED services.</p>
              <p>2.2 You are responsible for maintaining the confidentiality of your account credentials and for all activity under your account.</p>
              <p>2.3 ARED may suspend or restrict access if there is misuse or breach of this Agreement.</p>
              <p>2.4 If services are used on your premises by end users (e.g., staff, customers, visitors), you remain responsible for their use of the services.</p>

              <h3 class="mt-4 font-semibold">3. LICENSE & RESTRICTIONS</h3>
              <p>3.1 ARED grants you a non-exclusive, non-transferable, limited license to use the activated applications in accordance with this Agreement.</p>
              <p>3.2 You agree not to (i) reverse engineer, decompile, or tamper with the services, (ii) use services for unlawful purposes, or (iii) infringe any intellectual property rights.</p>
              <p>3.3 You shall not sublicense, resell, or make services available to third parties unless explicitly permitted in writing by ARED.</p>
              <p>3.4 If the ARED Edge Gateway has been provided to you at no cost as part of a service subscription, you acknowledge that the device remains the property of ARED. You agree not to open, alter, reprogram, or tamper with the device in any way.</p>
              <p>3.5 Upon cancellation or termination of your service, ARED reserves the right to collect the Edge Gateway hardware. You agree to return the device in good condition within fourteen (14) days or grant ARED access to retrieve the hardware.</p>

              <h3 class="mt-4 font-semibold">4. FEES & BILLING</h3>
              <p>4.1 Service fees are billed via your internet provider or ARED directly, depending on the deployment model.</p>
              <p>4.2 You agree to pay for all services you activate based on the current pricing communicated during activation.</p>
              <p>4.3 Non-payment may result in suspension or deactivation of services.</p>
              <p>4.4 Pricing may vary based on features, volume usage, or location. ARED reserves the right to revise pricing with prior notice.</p>

              <h3 class="mt-4 font-semibold">5. DATA PRIVACY & PROTECTION</h3>
              <p>5.1 ARED complies with applicable international data protection regulations.</p>
              <p>5.2 You retain ownership of your data. ARED will only process data to provide and improve services.</p>
              <p>5.3 All analytics will be anonymized unless otherwise consented.</p>
              <p>5.4 If you provide data relating to third-party end users (e.g., through surveys, feedback, or customer tracking), you are responsible for obtaining proper consent from such individuals.</p>
              <p>5.5 In the event of a data breach, ARED will notify affected parties within 48 hours.</p>
              <p>5.6 You agree that ARED is not liable for any legal claims arising from your failure to obtain proper end user consent.</p>

              <h3 class="mt-4 font-semibold">6. SERVICE AVAILABILITY</h3>
              <p>6.1 While ARED services are built to run online and offline, uptime may be affected by local internet or power conditions.</p>
              <p>6.2 ARED does not guarantee 100% uptime and is not liable for temporary unavailability due to maintenance or outages.</p>
              <p>6.3 Scheduled maintenance or updates will be communicated in advance whenever possible.</p>

              <h3 class="mt-4 font-semibold">7. INTELLECTUAL PROPERTY</h3>
              <p>7.1 All rights, title, and interest in the ARED platform, services, software, content, and brand remain the exclusive property of ARED.</p>
              <p>7.2 You may not use ARED branding without prior written consent.</p>
              <p>7.3 Suggestions or feedback provided to ARED regarding the services may be used to improve the platform without compensation or attribution.</p>

              <h3 class="mt-4 font-semibold">8. TERMINATION</h3>
              <p>8.1 You may deactivate services or terminate your account at any time through the dashboard.</p>
              <p>8.2 ARED reserves the right to suspend or terminate accounts that violate the terms of this Agreement.</p>
              <p>8.3 Upon termination, access to services and data will be discontinued after 30 days unless otherwise required by law.</p>
              <p>8.4 If ARED has provided Edge Gateway hardware to you, it must be returned within fourteen (14) days of termination. Failure to return the device may result in replacement charges.</p>

              <h3 class="mt-4 font-semibold">9. LIMITATION OF LIABILITY</h3>
              <p>9.1 To the fullest extent permitted by law, ARED shall not be liable for any indirect, incidental, or consequential damages.</p>
              <p>9.2 Our total liability shall not exceed the fees paid by you for services in the three (3) months preceding the claim.</p>
              <p>9.3 ARED is not liable for damages caused by misuse of services by your staff, customers, or third-party users.</p>
              <p>9.4 You agree to indemnify and hold ARED harmless from any third-party claims related to your use of the platform, including improper use of AI tools, collection of unauthorized end-user data, or failure to return ARED hardware.</p>

              <h3 class="mt-4 font-semibold">10. CHANGES TO TERMS</h3>
              <p>10.1 We may update this Agreement from time to time. You will be notified via the dashboard or email.</p>
              <p>10.2 Continued use of the services after an update constitutes acceptance of the new terms.</p>

              <h3 class="mt-4 font-semibold">11. GOVERNING LAW</h3>
              <p>11.1 This Agreement is governed by the laws of the Republic of Rwanda.</p>
              <p>11.2 Disputes shall be resolved through the Commercial Arbitration Court in Kigali.</p>

              <h3 class="mt-4 font-semibold">12. CONTACT</h3>
              <p>
                If you have any questions regarding this Agreement, please contact us at:<br />
                📧 <a href="mailto:info@aredgroup.com" class="text-blue-600">info@aredgroup.com</a><br />
                📍 East Wing, KG 7 Ave, Kigali Heights, 4th Floor, Kigali, Rwanda
              </p>
              <p class="mt-2"><strong>End of Agreement</strong></p>
            </div>
            <div class="flex flex-col items-center">
              <label class="flex items-center mb-2">
                <input
                  type="checkbox"
                  :disabled="!isCheckboxEnabled"
                  v-model="hasAgreed"
                  class="mr-2"
                  @change="handleCheckboxChange"
                />
                <span :class="isCheckboxEnabled ? 'text-gray-800' : 'text-gray-400'">I have read and agree to the terms</span>
              </label>
            </div>
          </div>
        </div>

        <div class="max-form w-full absolute bottom-5 lg:flex hidden justify-between items-center">
          <ul class="flex">
            <li>
              <span class="text-success font-poppins font-medium text-[0.778rem] mr-4"><a href="javascript:void(0)">Terms</a></span>
            </li>
            <li>
              <span class="text-success font-poppins font-medium text-[0.778rem] mr-4"><a href="javascript:void(0)">Plans</a></span>
            </li>
            <li>
              <span class="text-success font-poppins font-medium text-[0.778rem]"><a href="javascript:void(0)">Contact Us</a></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="lg:w-[50%]">
      <div class="flex justify-center items-center">
        <auth-right-image />
      </div>
    </div>
  </div>
</template>

<style scoped>
@media screen and (max-width: 1023px) {
  .max-form {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    height: 100vh;
    background: linear-gradient(180deg, #4c8a6c 0%, #0b1c14 100%);
  }
}
</style>

<script lang="ts">
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { defineComponent, ref, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import router from '@/router';
import instance from '@/axios-interceptor';
import { required } from '@vuelidate/validators';
import TextField from '../../components/reusable/TextField.vue';
import AuthRightImage from '../../components/markup/AuthRightImage.vue';
import type { Ref } from 'vue';

export default defineComponent({
  name: 'LoginPageOTP',
  components: {
    AuthRightImage,
    TextField,
  },
  setup() {
    const formData = ref({ otp: '' });
    const loading = ref(false);
    const showAgreement = ref(false);
    const isCheckboxEnabled = ref(false);
    const hasAgreed = ref(false);
    const scrollDiv: Ref<HTMLElement | null> = ref(null);

    const rules = { otp: { required } };
    const v$ = useVuelidate(rules, formData);

    const handleScroll = () => {
      const div = scrollDiv.value;
      if (div) {
        if (div.scrollTop + div.clientHeight >= div.scrollHeight - 5) {
          isCheckboxEnabled.value = true;
        }
      }
    };

    return {
      formData,
      v$,
      loading,
      showAgreement,
      isCheckboxEnabled,
      hasAgreed,
      scrollDiv,
      handleScroll,
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'hasFetchedUserInfo', 'loggedInUser']),
  },
  methods: {
    ...mapActions(['fetchUser']),
    ...mapMutations(['setAuthenticated']),
    async handleLogin() {
      if (this.formData.otp !== '') {
        this.loading = true;

        try {
          await instance.post(`2fa/verify/`, { token: this.formData.otp });
          this.$toast.success('OTP verified successfully.', { position: 'top-right' });

          if (!this.hasFetchedUserInfo) {
            await this.fetchUser();
          }
          if (!this.isAuthenticated) {
            this.setAuthenticated();
          }
          this.loading = false;

          this.checkAgreement();
        } catch (error: any) {
          this.loading = false;
          this.$toast.error('Invalid OTP', { position: 'top-right' });
        }
      }
    },
    checkAgreement() {
      const userId = this.loggedInUser?.id;
      if (!userId) {
        this.$toast.error('User data not available.', { position: 'top-right' });
        return;
      }
      const termsKey = `termsAgreed_${userId}`;
      if (localStorage.getItem(termsKey) !== 'true') {
        this.showAgreement = true;
      } else {
        this.proceedToDashboard();
      }
    },
    handleCheckboxChange() {
      if (this.hasAgreed) {
        const userId = this.loggedInUser.id;
        const termsKey = `termsAgreed_${userId}`;
        localStorage.setItem(termsKey, 'true');
        this.$toast.success('Terms agreed. Redirecting in 5 seconds...', { position: 'top-right', duration: 5000 });
        setTimeout(() => {
          this.showAgreement = false;
          this.proceedToDashboard();
        }, 5000);
      }
    },
    proceedToDashboard() {
      router.push('/dashboard');
    },
    handleResend() {
      this.loading = true;
      instance
        .post(`2fa/`)
        .then((response) => {
          this.loading = false;
          this.$toast.success(response.data.detail, { position: 'top-right' });
        })
        .catch((error: any) => {
          this.loading = false;
          this.$toast.error(error.response.data.detail, { position: 'top-right' });
        });
    },
  },
});
</script>