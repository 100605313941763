<template>
  <DashboardHeader title="Shiriki Add-Ons" icon="fa-solid fa-puzzle-piece" iconType="class">
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-4 py-2 rounded-lg flex items-center shadow-sm">
        <span class="font-poppins font-medium text-sm md:text-base text-[#959CBD]">Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span>
        </span>
        <img src="../../assets/today.png" alt="africa" class="w-5 h-5 ml-2 rounded-full" />
      </div>
      <button v-if="loggedInUser.partner.has_food_drink_menu_feature_enabled"
        type="button"
        class="bg-[#4E8D6D] px-5 py-2 rounded-lg flex items-center text-white font-poppins font-semibold text-sm md:text-base hover:bg-[#3d7054] transition-colors shadow-md"
        @click="showUploadMenuModal = true">
        <img src="../../assets/plus-icon.png" class="w-4 h-4 mr-2" alt="" />
        Add Menu
      </button>
    </template>
  </DashboardHeader>

  <div class="w-full my-6">
    <div class="bg-gradient-to-br from-white to-[#F9FAFB] w-full min-h-[30rem] rounded-xl p-6 shadow-lg">
      <!-- Grid of Add-On Cards -->
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        <div v-for="addon in addons" :key="addon.id"
          class="bg-white rounded-xl p-6 shadow-md hover:shadow-lg hover:bg-gray-50 transition-all duration-300 cursor-pointer group border border-gray-100"
          @click="openPopup(addon.id)">
          <div class="flex justify-between items-start mb-4">
            <div class="w-10 h-10 rounded-full flex items-center justify-center bg-[#DCE9E3] text-[#4E8D6D]">
              <i :class="getIcon(addon.icon)" class="text-lg"></i>
            </div>
            <div class="flex items-center gap-2">
              <button v-if="isActive(addon.id)"
                class="h-7 px-2 text-xs border border-[#F24236] text-[#F24236] rounded-md font-poppins font-medium hover:bg-[#F24236] hover:text-white transition-colors"
                @click.stop="deactivateAddOn(addon.deactivateKey)" :disabled="deactivating">
                <i class="fa-solid fa-power-off w-3 h-3 mr-1 inline"></i>
                {{ deactivating ? 'Deactivating...' : 'Deactivate' }}
              </button>
              <button v-else
                class="h-7 px-2 text-xs border border-[#4E8D6D] text-[#4E8D6D] rounded-md font-poppins font-medium hover:bg-[#4E8D6D] hover:text-white transition-colors"
                @click.stop="openPopup(addon.id)">
                <i class="fa-solid fa-bolt w-3 h-3 mr-1 inline"></i>
                Activate
              </button>
              <span :class="[
                'px-2 py-1 text-xs font-poppins font-medium rounded-full transition-all duration-300',
                isActive(addon.id) ? 'bg-[#4E8D6D] text-white hover:bg-[#3d7054]' : 'bg-gray-200 text-gray-600'
              ]">
                {{ isActive(addon.id) ? 'Active' : 'Inactive' }}
              </span>
            </div>
          </div>
          <h3 class="font-poppins font-semibold text-lg text-[#464E5F] mb-2">{{ addon.name }}</h3>
          <p class="text-[#6B7280] text-sm mb-4">{{ addon.description }}</p>
          <div class="mt-4 pt-4 border-t border-gray-200 flex justify-between items-center">
            <div class="text-xs text-[#6B7280]">
              <span class="font-medium"></span>
            </div>
            <div class="w-8 h-8 rounded-full flex items-center justify-center bg-[#F3F6F9] text-[#4E8D6D] group-hover:bg-[#4E8D6D] group-hover:text-white transition-colors duration-300">
              <i class="fa-solid fa-plus w-4 h-4"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Popup Modal -->
  <div v-if="showPopup" class="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 px-4 transition-all duration-500 ease-out"
    :class="{ 'bg-opacity-10': !showPopup }">
    <div class="fixed inset-x-0 top-1/2 -translate-y-1/2 max-w-2xl mx-auto bg-white rounded-xl p-0 overflow-hidden shadow-lg transition-all duration-500 ease-out"
      :class="{ 'opacity-100 scale-100': showPopup, 'opacity-0 scale-95 pointer-events-none': !showPopup }">
      <div class="relative flex flex-col h-[80vh]">
        <!-- Colorful Top Bar -->
        <div class="w-full h-2 bg-[#4E8D6D]"></div>
        <!-- Close Button -->
        <button class="absolute top-4 right-4 p-1 rounded-full bg-gray-200 text-gray-600 hover:bg-[#F24236] hover:text-white transition-colors"
          @click="closePopup">
          <i class="fa-solid fa-xmark w-4 h-4"></i>
        </button>
        <!-- Scrollable Content -->
        <div class="flex-1 overflow-y-auto p-6">
          <h2 class="font-poppins font-bold text-2xl text-[#464E5F] mb-4">{{ selectedAddonObj.name }}</h2>
          <p class="text-[#6B7280] text-sm mb-6">{{ selectedAddonObj.description }}</p>

          <!-- SMS Package Addon -->
          <div v-if="selectedAddon === 'sms-package' && loggedInUser.partner.has_voucher_sms_feature_enabled" class="mt-6">
            <SMSAddOn></SMSAddOn>
            <h3 class="font-poppins font-semibold text-lg text-[#4E8D6D] mb-3">SMS Token Duration</h3>
            <p class="text-[#6B7280] text-sm mb-4">
              The voucher SMS feature lets users request a WiFi token via SMS, instantly connecting them without needing manual token distribution...
            </p>
            <div class="flex flex-col sm:flex-row items-start sm:items-center gap-4">
              <label class="font-poppins text-sm font-medium text-[#464E5F]">Token Duration:</label>
              <select v-model="duration" class="w-full sm:w-48 h-10 border border-gray-300 rounded-md px-3 text-sm focus:ring-2 focus:ring-[#4E8D6D] focus:border-[#4E8D6D] outline-none transition-all">
                <option value="Select Default Duration" disabled selected>Select Default Duration</option>
                <option value="1_hour">1 Hour</option>
                <option value="2_hours">2 Hours</option>
                <option value="5_hours">5 Hours</option>
                <option value="10_hours">10 Hours</option>
                <option value="24_hours">24 Hours</option>
                <option value="unlimited">Unlimited</option>
                <option value="custom">Custom Minutes</option>
              </select>
              <input v-if="duration === 'custom'" v-model="customDuration" type="number" placeholder="Minutes"
                class="w-full sm:w-24 h-10 border border-gray-300 rounded-md px-3 text-sm focus:ring-2 focus:ring-[#4E8D6D] focus:border-[#4E8D6D] outline-none transition-all" />
            </div>
          </div>

          <!-- Food & Drink Menu Addon -->
          <div v-else-if="selectedAddon === 'food-drink-menu' && loggedInUser.partner.has_food_drink_menu_feature_enabled" class="mt-6">
            <DashboardTableTitle v-if="propLoaded" :perPageItems="perSize" :pageNumber="page" :ApiData="foodDrinkMenus"
              @handleNext="handlleNext" @handlePrev="handlePrev">
              <template v-slot:section>
                <div class="mt-5 flex items-center gap-2">
                  <span class="text-[#B5B5C3] font-poppins font-medium text-sm">Rows per page</span>
                  <select v-model="selectedOption" @change="handleChange"
                    class="bg-[#F3F6F9] h-8 px-2 rounded-lg text-[#464E5F] font-poppins font-semibold text-sm focus:ring-2 focus:ring-[#4E8D6D] outline-none">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                  </select>
                </div>
              </template>
            </DashboardTableTitle>
            <SmeTable :tableHeaderData="foodDrinkMenuTableHeaders">
              <template v-slot:tableBodyData>
                <template v-if="foodDrinkMenus.length">
                  <div v-for="menu in foodDrinkMenus" :key="menu.id"
                    class="flex items-center justify-between py-3 px-4 bg-gray-50 rounded-lg my-2 text-sm text-[#B5B5C3] font-poppins font-medium hover:bg-gray-100 transition-colors">
                    <span class="text-[#464E5F] capitalize">{{ menu.menu_type }}</span>
                    <span class="text-[#464E5F]">{{ menu.realm.name }}</span>
                    <span class="text-[#464E5F]">{{ menu.original_file_name }}</span>
                    <span class="text-[#464E5F]">{{ createdAt(menu.created) }}</span>
                    <span @click="downloadFile(menu.file)"
                      class="p-2 bg-[#4E8D6D] text-white rounded-md cursor-pointer hover:bg-[#3d7054] transition-colors">
                      Download <i class="ml-1 fa-solid fa-download"></i>
                    </span>
                    <div class="flex items-center">
                      <span v-if="!deleting || currentDeletionFileID !== menu.id" @click="deleteMenu(menu.id)"
                        class="p-2 bg-[#F24236] text-white rounded-md cursor-pointer hover:bg-[#d32f2f] transition-colors">
                        Delete
                      </span>
                      <ScaleLoader v-if="deleting && currentDeletionFileID === menu.id" color="#d7191c"></ScaleLoader>
                    </div>
                  </div>
                </template>
                <div v-else class="text-center text-sm text-[#afafaf] mt-6">
                  <b>No Food & Drinks Menus Found. Click "Add Menu" to get started.</b>
                </div>
              </template>
            </SmeTable>
          </div>

          <!-- Auto-Connect Addon -->
          <div v-else-if="selectedAddon === 'autoconnect' && loggedInUser.partner.has_autoconnect_addon_enabled" class="mt-6">
            <h3 class="font-poppins font-semibold text-lg text-[#4E8D6D] mb-3">Auto-Connect Token Duration</h3>
            <p class="text-[#6B7280] text-sm mb-4">
              Our Auto-Connect Token Duration feature automatically generates a token when the user connects...
            </p>
            <div class="flex flex-col sm:flex-row items-start sm:items-center gap-4">
              <label class="font-poppins text-sm font-medium text-[#464E5F]">Token Duration:</label>
              <select v-model="duration" class="w-full sm:w-48 h-10 border border-gray-300 rounded-md px-3 text-sm focus:ring-2 focus:ring-[#4E8D6D] focus:border-[#4E8D6D] outline-none transition-all">
                <option value="Select Default Duration" disabled selected>Select Default Duration</option>
                <option value="1_hour">1 Hour</option>
                <option value="2_hours">2 Hours</option>
                <option value="5_hours">5 Hours</option>
                <option value="10_hours">10 Hours</option>
                <option value="24_hours">24 Hours</option>
                <option value="custom">Custom Minutes</option>
              </select>
              <input v-if="duration === 'custom'" v-model="customDuration" type="number" placeholder="Minutes"
                class="w-full sm:w-24 h-10 border border-gray-300 rounded-md px-3 text-sm focus:ring-2 focus:ring-[#4E8D6D] focus:border-[#4E8D6D] outline-none transition-all" />
            </div>
            <div class="flex items-center mt-4 gap-4">
              <label for="autoconnect-daily-usage" class="font-poppins text-sm font-medium text-[#464E5F]">Daily Usage Limit:</label>
              <input type="checkbox" id="autoconnect-daily-usage" v-model="autoconnectDailyUsage" class="w-5 h-5 text-[#4E8D6D] rounded focus:ring-[#4E8D6D]" />
            </div>
            <p class="text-[#6B7280] text-sm mt-2">Enabling this limits daily free internet access to the specified duration.</p>
          </div>

          <!-- Token Purchase Addon -->
          <div v-else-if="selectedAddon === 'token-purchase' && loggedInUser.partner.has_token_purchase_addon_enabled" class="mt-6">
            <h3 class="font-poppins font-semibold text-lg text-[#4E8D6D] mb-3">Token Purchase Add-On</h3>
            <p class="text-[#6B7280] text-sm mb-4">
              Enhance your captive portal with our Token Purchase Addon...
            </p>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div class="space-y-4">
                <div>
                  <p class="text-[#464E5F] font-poppins font-medium text-sm">
                    <strong>Free Access Configuration:</strong> Administrators can set a default duration...
                  </p>
                </div>
                <div>
                  <p class="text-[#464E5F] font-poppins font-medium text-sm">
                    <strong>Daily Free Access Restriction:</strong> Free access is limited to a daily basis...
                  </p>
                </div>
                <div>
                  <p class="text-[#464E5F] font-poppins font-medium text-sm">
                    <strong>Token Purchase:</strong> Once the free access period expires...
                  </p>
                </div>
                <div>
                  <p class="text-[#464E5F] font-poppins font-medium text-sm">
                    <strong>User Experience:</strong> When users connect to the WiFi network...
                  </p>
                </div>
              </div>
              <div>
                <h4 class="font-poppins font-semibold text-md text-[#4E8D6D] mb-3">Token Durations and Prices</h4>
                <div class="space-y-4">
                  <div v-for="(option, index) in tokenPurchaseOptions" :key="index" class="bg-gray-50 p-4 rounded-lg shadow-sm hover:bg-gray-100 transition-colors duration-200">
                    <div v-if="editMode" class="flex flex-col gap-2">
                      <div class="flex items-center gap-2">
                        <label class="font-poppins text-sm font-medium text-[#464E5F]">Price (RWF):</label>
                        <input v-model="option.price" type="text" class="w-full h-8 border border-gray-300 rounded-md px-2 text-sm focus:ring-2 focus:ring-[#4E8D6D] outline-none" />
                      </div>
                      <div class="flex items-center gap-2">
                        <label class="font-poppins text-sm font-medium text-[#464E5F]">Duration (minutes):</label>
                        <input v-model="option.duration_in_minutes" type="number" class="w-full h-8 border border-gray-300 rounded-md px-2 text-sm focus:ring-2 focus:ring-[#4E8D6D] outline-none" />
                      </div>
                    </div>
                    <div v-else>
                      <p class="text-[#464E5F] font-poppins font-medium text-sm">
                        <strong>Price (RWF):</strong> {{ option.price }}
                      </p>
                      <p class="text-[#464E5F] font-poppins font-medium text-sm">
                        <strong>Duration:</strong> {{ option.display_duration }}
                      </p>
                    </div>
                  </div>
                </div>
                <button @click="editMode = !editMode" class="mt-4 bg-[#4E8D6D] text-white font-poppins font-medium text-sm rounded-md px-4 py-2 hover:bg-[#3d7054] transition-colors">
                  {{ editMode ? 'Cancel' : 'Edit' }}
                </button>
              </div>
            </div>
          </div>

          <!-- Queue System Addon -->
          <div v-else-if="selectedAddon === 'queue' && loggedInUser.partner.has_queue_management_addon_enabled" class="mt-6">
            <h3 class="font-poppins font-semibold text-lg text-[#4E8D6D] mb-3">Queue System</h3>
            <p class="text-[#6B7280] text-sm mb-4">Our Smart Queue Management System streamlines customer flow by organizing and optimizing wait times...</p>
          </div>

          <!-- Survey Addon -->
          <div v-else-if="selectedAddon === 'survey' && loggedInUser.partner.has_survey_addon_enabled" class="mt-6">
            <h3 class="font-poppins font-semibold text-lg text-[#4E8D6D] mb-3">Survey Add-On</h3>
            <p class="text-[#6B7280] text-sm mb-4">Create surveys, share them instantly, and view live responses to gather valuable feedback...</p>
          </div>

          <!-- HMS Addon -->
          <div v-else-if="selectedAddon === 'hms' && loggedInUser.partner.has_hms_addon_enabled" class="mt-6">
            <h3 class="font-poppins font-semibold text-lg text-[#4E8D6D] mb-3">HMS Add-On</h3>
            <p class="text-[#6B7280] text-sm mb-4">Process orders, track inventory, and analyze data to streamline restaurant workflow.</p>
          </div>

          
<!-- Web App Login Addon -->
<div v-else-if="selectedAddon === 'web-app-login' && loggedInUser.partner.has_web_app_login_enabled" class="mt-6">
  <h3 class="font-poppins font-semibold text-lg text-[#4E8D6D] mb-3">Web App Login</h3>
  <p class="text-[#6B7280] text-sm mb-4">
    This feature is enabled by default, requiring users to log in to access the web app. Select the information you want to collect from users below, then click "Save". Deactivate to disable the login requirement.
  </p>
  <div class="space-y-4">
    <!-- Selected Fields with Remove Buttons -->
    <div v-if="selectedFields.length" class="bg-gray-50 p-4 rounded-lg">
      <h4 class="font-poppins font-medium text-md text-[#464E5F] mb-3">Selected Fields:</h4>
      <ul class="list-disc pl-5 text-sm text-[#6B7280]">
        <li v-for="field in selectedFields" :key="field" class="flex justify-between items-center py-1">
          <span>{{ field }}</span>
          <button 
            @click="removeField(field)" 
            class="text-[#F24236] hover:text-[#d32f2f] text-xs font-medium"
          >
            Remove
          </button>
        </li>
      </ul>
    </div>
    <p v-else class="text-[#6B7280] text-sm">No fields selected yet.</p>

    <div class="bg-gray-50 p-4 rounded-lg">
  <h4 class="font-poppins font-medium text-md text-[#464E5F] mb-3">Available Fields:</h4>
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
    <label v-for="field in availableFields" :key="field" class="flex items-center gap-2">
      <input 
        type="checkbox" 
        :value="field" 
        v-model="loggedInUser.partner[fieldToKey[field]]"
        class="w-5 h-5 text-[#4E8D6D] rounded focus:ring-[#4E8D6D]"
      />
      <span class="text-[#464E5F] font-poppins text-sm">{{ field }}</span>
    </label>
  </div>
</div>
    
  </div>
</div>

          <!-- Inactive Addon Activation -->
          <div v-else class="mt-6">
            <activate-add-on :noActiveAddOnTitle="`${selectedAddonObj.name} Inactive`"
              :noActiveAddOnMessage="selectedAddonObj.description"
              :processingAddOnActivation="processingAddOnActivation" :price="0"
              @activate-add-on="activateSelectedAddon"></activate-add-on>
          </div>
        </div>

        <!-- Buttons (Sticky Footer for Active Addons) -->
        <div v-if="isActive(selectedAddonObj.id)" class="p-6 border-t border-gray-200 bg-white flex justify-end space-x-4">
          <button class="border border-gray-300 text-[#464E5F] font-poppins font-medium text-sm rounded-md px-6 py-2 hover:bg-gray-100 transition-colors"
            @click="deactivateAddOn(selectedAddonObj.deactivateKey)">
            Deactivate
          </button>
          <button class="bg-[#4E8D6D] text-white font-poppins font-medium text-sm rounded-md px-6 py-2 hover:bg-[#3d7054] transition-colors"
            @click="saveOrActivate">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>

  <upload-menu v-if="showUploadMenuModal" @close="closeModal()" />
</template>

<script lang="ts">
import moment from 'moment';
import router from '@/router';
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';
import instance from '../../axios-interceptor';
import { FoodDrinkMenu, TokenPurchaseOption } from '@/interfaces/router';
import SmeTable from '@/components/reusable/SmeTable.vue';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import UploadMenu from '@/components/Common/UploadMenu.vue';
import ActivateAddOn from '@/components/Common/ActivateAddOn.vue';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import SMSAddOn from '@/components/Common/SMSAddOn.vue';
import { foodDrinkMenuTableHeaders as _foodDrinkMenuTableHeaders } from '../../helpers/stub_data/dashboard_routers';

// Define types for loggedInUser and Partner
interface Partner {
  id: number;
  has_voucher_sms_feature_enabled: boolean;
  has_food_drink_menu_feature_enabled: boolean;
  has_autoconnect_addon_enabled: boolean;
  has_token_purchase_addon_enabled: boolean;
  has_queue_management_addon_enabled: boolean;
  has_survey_addon_enabled: boolean;
  has_hms_addon_enabled: boolean;
  has_rating_feature_enabled?: boolean;
  voucher_sms_feature_enabled?: boolean;
  has_web_app_login_enabled: boolean;
  has_camera_solution_addon_enabled: boolean;
  collect_age: boolean;
  collect_country: boolean;
  collect_phone: boolean;
  collect_address: boolean;
  collect_gender: boolean;
}

interface LoggedInUser {
  partner: Partner;
}

// Define Addon type
interface Addon {
  id: string;
  name: string;
  description: string;
  deactivateKey: string;
  icon: string;
}
const fieldToKey = {
  Age: 'collect_age',
  Country: 'collect_country',
  Phone: 'collect_phone',
  Address: 'collect_address',
  Gender: 'collect_gender',
} as const;

type FieldKey = keyof typeof fieldToKey;

type CollectKey = typeof fieldToKey[FieldKey];

export default defineComponent({
  name: 'ShirikiAddOn',
  components: {
    SmeTable,
    UploadMenu,
    ScaleLoader,
    ActivateAddOn,
    DashboardHeader,
    SMSAddOn,
  },
  setup() {
    const foodDrinkMenuTableHeaders = ref([..._foodDrinkMenuTableHeaders]);
    const deleting = ref(false);
    const deactivating = ref(false);
    const currentDeletionFileID = ref(0);
    const newField = ref('');
    const allFields = ref<FieldKey[]>(['Age', 'Country', 'Phone', 'Address', 'Gender']);
   

    return {
      deleting,
      deactivating,
      currentDeletionFileID,
      foodDrinkMenuTableHeaders,
      allFields,
      fieldToKey,
    };
  },
  data() {
    return {
      activeTab: 'sms-package',
      loading: false,
      selectedOption: 10,
      apiData: [],
      propLoaded: false,
      page: 1,
      perSize: 10,
      showUploadMenuModal: false,
      processingSMSPackage: false,
      foodDrinkMenus: [] as FoodDrinkMenu[],
      processingAddOnActivation: false,
      processingRatingFeature: false,
      showModal: false,
      searchQuery: '',
      showCancellationDialog: false,
      loadingCancellation: false,
      loadingVoucherDurationPreference: false,
      duration: 'Select Default Duration',
      customDuration: null as number | null,
      autoconnectDailyUsage: false,
      loadingTokenPurchasePreference: false,
      editMode: false,
      tokenPurchaseOptions: [
        { price: '0 RWF', duration_in_minutes: 10, display_duration: '10 minutes (daily free)' },
        { price: '100 RWF', duration_in_minutes: 20, display_duration: '20 minutes' },
        { price: '300 RWF', duration_in_minutes: 60, display_duration: '1 hour' },
        { price: '500 RWF', duration_in_minutes: 180, display_duration: '3 hours' },
        { price: '1000 RWF', duration_in_minutes: 1440, display_duration: '1 day' },
      ] as TokenPurchaseOption[],
      addOnToDeactivate: '',
      showPopup: false,
      selectedAddon: '',
      addons: [
        { id: 'sms-package', name: 'SMS Package', description: 'Send WiFi tokens via SMS.', deactivateKey: 'sms-addon', icon: 'fa-solid fa-envelope' },
        { id: 'autoconnect', name: 'Auto-Connect', description: 'Seamless internet access.', deactivateKey: 'autoconnect-addon', icon: 'fa-solid fa-cloud' },
        { id: 'token-purchase', name: 'Token Purchase', description: 'Sell tokens for internet access.', deactivateKey: 'token-purchase-addon', icon: 'fa-solid fa-credit-card' },
        { id: 'queue', name: 'Queue System', description: 'Manage customer queues efficiently.', deactivateKey: 'queue', icon: 'fa-solid fa-chart-bar' },
        { id: 'survey', name: 'Survey', description: 'Create surveys, share them instantly, and view live responses.', deactivateKey: 'survey', icon: 'fa-solid fa-chart-bar' },
        { id: 'hms', name: 'HMS', description: 'Manage orders, track inventory, and streamline restaurant operations.', deactivateKey: 'hms', icon: 'fa-solid fa-utensils' },
        { id: 'web-app-login', name: 'Web App Login', description: 'Enabled by default. Deactivate to remove web app login requirement.', deactivateKey: 'web-app-login', icon: 'fa-solid fa-lock' },
        { id: 'camera', name: 'Camera System', description: 'Activate to monitor locations, detect motion, and improve security.', deactivateKey: 'camera', icon: 'fa-solid fa-camera' },
      ] as Addon[],
    };
  },
  computed: {
    // Type the computed properties explicitly
    loggedInUser: function (): LoggedInUser {
      return this.$store.getters.loggedInUser as LoggedInUser;
    },
    today(): string {
      return moment(Date.now()).format('MMMM Do');
    },
    selectedAddonObj(): Addon {
      return this.addons.find((addon: Addon) => addon.id === this.selectedAddon) || ({} as Addon);
    },
    addonStatus(): Record<string, boolean> {
      return {
        'sms-package': this.loggedInUser?.partner?.has_voucher_sms_feature_enabled ?? false,
        'food-drink-menu': this.loggedInUser?.partner?.has_food_drink_menu_feature_enabled ?? false,
        'autoconnect': this.loggedInUser?.partner?.has_autoconnect_addon_enabled ?? false,
        'token-purchase': this.loggedInUser?.partner?.has_token_purchase_addon_enabled ?? false,
        'queue': this.loggedInUser?.partner?.has_queue_management_addon_enabled ?? false,
        'survey': this.loggedInUser?.partner?.has_survey_addon_enabled ?? false,
        'hms': this.loggedInUser?.partner?.has_hms_addon_enabled ?? false,
        'web-app-login': this.loggedInUser?.partner?.has_web_app_login_enabled ?? true,
        'camera': this.loggedInUser?.partner?.has_camera_solution_addon_enabled ?? false,
      };
    },
    partnerFields(): Record<string, boolean> {
      return {
        collect_age: this.loggedInUser.partner.collect_age,
        collect_country: this.loggedInUser.partner.collect_country,
        collect_phone: this.loggedInUser.partner.collect_phone,
        collect_address: this.loggedInUser.partner.collect_address,
        collect_gender: this.loggedInUser.partner.collect_gender,
      };
    },
    selectedFields(): FieldKey[] {
      return this.allFields.filter((field: FieldKey) => this.partnerFields[this.fieldToKey[field]]);
    },
    availableFields(): FieldKey[] {
      return this.allFields.filter((field: FieldKey) => !this.partnerFields[this.fieldToKey[field]]);
    },
  },
  methods: {
    async removeField(field: FieldKey) {
  try {
    const key = this.fieldToKey[field];
    this.loggedInUser.partner[key as CollectKey] = false;

    // Send PATCH request to update the server
    await instance.patch(`partners/${this.loggedInUser.partner.id}/`, {
      [key]: false,
    });

    // Commit to Vuex after successful server update
    this.$store.commit('updatePartner', this.loggedInUser.partner);
    this.$toast.success(`${field} removed successfully`, { position: 'top-right' });
  } catch (error) {
    console.error(`Error removing ${field}:`, error);
    this.$toast.error(`Failed to remove ${field}`, { position: 'top-right' });
  }
},
    async saveWebAppLoginFields() {
  try {
    // Prepare the payload with all fields, not just selected ones, to ensure full sync
    const payload = {
      collect_age: this.loggedInUser.partner.collect_age,
      collect_country: this.loggedInUser.partner.collect_country,
      collect_phone: this.loggedInUser.partner.collect_phone,
      collect_address: this.loggedInUser.partner.collect_address,
      collect_gender: this.loggedInUser.partner.collect_gender,
    };

    // Send PATCH request to update the partner on the server
    await instance.patch(`partners/${this.loggedInUser.partner.id}/`, payload);

    // Update the local partner object and Vuex store
    Object.assign(this.loggedInUser.partner, payload);
    this.$store.commit('updatePartner', this.loggedInUser.partner);

    // Notify user of success
    this.$toast.success('Web App Login fields saved successfully', { position: 'top-right' });
    this.closePopup();
  } catch (error) {
    console.error('Error saving Web App Login fields:', error);
    this.$toast.error('Failed to save Web App Login fields', { position: 'top-right' });
  }
},
    getIcon(iconName: string): string {
      return iconName;
    },
    isActive(addonId: string): boolean {
      return this.addonStatus[addonId];
    },
    openPopup(addonId: string) {
      this.selectedAddon = addonId;
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
      this.selectedAddon = '';
    },
    activateSelectedAddon() {
      const activateMethod = {
        'sms-package': this.activateSMSAddOn,
        'food-drink-menu': this.activateFoodMenuAddOn,
        'autoconnect': this.activateAutoConnectAddOn,
        'token-purchase': this.activateTokenPurchaseAddOn,
        'queue': this.activateQueueAddon,
        'survey': this.activateSurveyAddon,
        'hms': this.activateHmsAddon,
        'web-app-login': this.activateWebAppLoginAddon,
        'camera': this.activateCameraAddon,
      }[this.selectedAddon];
      if (activateMethod) activateMethod();
    },
    setActiveTab(tab: string) {
      this.activeTab = tab;
      router.push({ query: { tab: tab } });
    },
    createdAt(date: Date): string | undefined {
      if (date) {
        return moment(date).format('dddd, MMMM Do YYYY, h:mm a');
      }
    },
    handlePrev(data: any) {
      this.page--;
    },
    handlleNext(data: any) {
      this.page++;
    },
    getDate(date: Date | number): string {
      return moment(date).calendar();
    },
    toggleCancellationDialog(value: boolean) {
      this.showCancellationDialog = value;
      if (!value) this.deactivating = false;
    },
    saveOrActivate() {
  if (this.isActive(this.selectedAddon)) {
    if (this.selectedAddon === 'sms-package' || this.selectedAddon === 'autoconnect') {
      this.setVoucherPreference();
    } else if (this.selectedAddon === 'token-purchase' && this.editMode) {
      this.saveTokenPurchasePreference();
    } else if (this.selectedAddon === 'web-app-login') {
          this.saveWebAppLoginFields();
      }
  } else {
    this.activateSelectedAddon();
  }
  this.editMode = false; // Reset edit mode after saving
},
    async fetchTokenPurchasePreferences() {
      try {
        this.loadingTokenPurchasePreference = true;
        const response = await instance.get('vouchers/purchase-preference/', {
          params: { partner: this.loggedInUser.partner.id },
        });
        if (response.data.length) {
          this.tokenPurchaseOptions = response.data.map((item: any) => ({
            price: item.price,
            duration_in_minutes: item.duration_in_minutes,
            display_duration: this.formatDuration(item.duration_in_minutes, item.price),
          }));
        }
      } catch (error) {
        console.error('Error fetching token purchase preferences:', error);
      } finally {
        this.loadingTokenPurchasePreference = false;
      }
    },
    async saveTokenPurchasePreference() {
      try {
        this.loading = true;
        const response = await instance.post('vouchers/purchase-preference/', this.tokenPurchaseOptions);
        console.log('Preferences saved successfully:', response.data);
      } catch (error) {
        console.error('Error saving token purchase preferences:', error);
      } finally {
        this.loading = false;
        this.editMode = false;
        this.updatedisplay_durations();
      }
    },
    updatedisplay_durations() {
      this.tokenPurchaseOptions.forEach(item => {
        item.display_duration = this.formatDuration(item.duration_in_minutes, item.price);
      });
    },
    formatDuration(durationInMinutes: number, price: string): string {
      const days = Math.floor(durationInMinutes / (24 * 60));
      const hours = Math.floor((durationInMinutes % (24 * 60)) / 60);
      const minutes = durationInMinutes % 60;
      let formattedDuration = '';
      if (days > 0) formattedDuration += `${days} day${days > 1 ? 's' : ''}`;
      if (hours > 0) formattedDuration += `${formattedDuration ? ' and ' : ''}${hours} hour${hours > 1 ? 's' : ''}`;
      if (minutes > 0 || !formattedDuration) formattedDuration += `${formattedDuration ? ' and ' : ''}${minutes} minute${minutes > 1 ? 's' : ''}`;
      if (price === '0 RWF') formattedDuration += ' (daily free)';
      return formattedDuration;
    },
    fetchFoodDrinkMenus() {
      instance
        .get(`food-drink-menu/?realm__partner=${this.loggedInUser.partner.id}`)
        .then((response: { data: any }) => {
          this.foodDrinkMenus = response.data;
        })
        .catch((error: any) => console.log(error));
    },
    downloadFile(file: string) {
      window.open(file, '_blank', 'noreferrer');
    },
    async deleteMenu(fileID: number) {
      try {
        this.currentDeletionFileID = fileID;
        this.deleting = true;
        await instance.delete(`food-drink-menu/${fileID}`).then(() => {
          this.$toast.success('The menu has been deleted successfully', { position: 'top-right' });
          this.deleting = false;
          this.currentDeletionFileID = 0;
          this.foodDrinkMenus = this.foodDrinkMenus.filter(menu => menu.id !== fileID);
        });
      } catch (e: any) {
        this.deleting = false;
        this.currentDeletionFileID = 0;
        this.$toast.error(e.response?.data?.detail || 'Could not delete the menu', { position: 'top-right' });
      }
    },
    activateSMSAddOn() {
      this.processingAddOnActivation = true;
      instance
        .patch(`partners/${this.loggedInUser.partner.id}/`, { has_voucher_sms_feature_enabled: true })
        .then(() => {
          this.processingAddOnActivation = false;
          this.$toast.success('SMS Add-On activated successfully', { position: 'top-right' });
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.processingAddOnActivation = false;
        });
    },
    activateFoodMenuAddOn() {
      this.processingAddOnActivation = true;
      instance
        .patch(`partners/${this.loggedInUser.partner.id}/`, { has_food_drink_menu_feature_enabled: true })
        .then(() => {
          this.processingAddOnActivation = false;
          this.$toast.success('Food & Menu Add-On activated successfully', { position: 'top-right' });
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.processingAddOnActivation = false;
        });
    },
    activateFeedbackAddOn() {
      this.processingAddOnActivation = true;
      instance
        .patch(`partners/${this.loggedInUser.partner.id}/`, { has_rating_feature_enabled: true })
        .then(() => {
          this.processingAddOnActivation = false;
          this.$toast.success('Feedback Add-On activated successfully', { position: 'top-right' });
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.processingAddOnActivation = false;
        });
    },
    activateAutoConnectAddOn() {
      this.processingAddOnActivation = true;
      instance
        .patch(`partners/${this.loggedInUser.partner.id}/`, { has_autoconnect_addon_enabled: true })
        .then(() => {
          this.processingAddOnActivation = false;
          this.$toast.success('Auto-Connect Add-On activated successfully', { position: 'top-right' });
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.processingAddOnActivation = false;
        });
    },
    activateTokenPurchaseAddOn() {
      this.processingAddOnActivation = true;
      instance
        .patch(`partners/${this.loggedInUser.partner.id}/`, { has_token_purchase_addon_enabled: true })
        .then(() => {
          this.processingAddOnActivation = false;
          this.$toast.success('Token Purchase Add-On activated successfully', { position: 'top-right' });
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.processingAddOnActivation = false;
        });
    },
    activateQueueAddon() {
      this.processingAddOnActivation = true;
      instance
        .patch(`partners/${this.loggedInUser.partner.id}/`, { has_queue_management_addon_enabled: true })
        .then(() => {
          this.processingAddOnActivation = false;
          this.$toast.success('Queue Management Add-On activated successfully', { position: 'top-right' });
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.processingAddOnActivation = false;
        });
    },
    activateCameraAddon() {
      this.processingAddOnActivation = true;
      instance
        .patch(`partners/${this.loggedInUser.partner.id}/`, { 
          has_camera_solution_addon_enabled: true })
        .then(() => {
          this.processingAddOnActivation = false;
          this.$toast.success('Camera System Add-On activated successfully', { position: 'top-right' });
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.processingAddOnActivation = false;
        });
    },
    activateSurveyAddon() {
      this.processingAddOnActivation = true;
      instance
        .patch(`partners/${this.loggedInUser.partner.id}/`, { has_survey_addon_enabled: true })
        .then(() => {
          this.processingAddOnActivation = false;
          this.$toast.success('Survey Add-On activated successfully', { position: 'top-right' });
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.processingAddOnActivation = false;
        });
    },
    activateHmsAddon() {
      this.processingAddOnActivation = true;
      instance
        .patch(`partners/${this.loggedInUser.partner.id}/`, { has_hms_addon_enabled: true })
        .then(() => {
          this.processingAddOnActivation = false;
          this.$toast.success('HMS Add-On activated successfully', { position: 'top-right' });
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.processingAddOnActivation = false;
        });
    },
    activateWebAppLoginAddon() {
      this.processingAddOnActivation = true;
      instance
        .patch(`partners/${this.loggedInUser.partner.id}/`, { has_web_app_login_enabled: true })
        .then(() => {
          this.processingAddOnActivation = false;
          this.$toast.success('Web App Login Add-On activated successfully', { position: 'top-right' });
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.processingAddOnActivation = false;
          this.$toast.error('Failed to activate Web App Login Add-On', { position: 'top-right' });
        });
    },
    closeModal() {
      this.showUploadMenuModal = false;
      this.fetchFoodDrinkMenus();
    },
    handleChange() {
      this.perSize = this.selectedOption;
    },
    deactivateAddOn(addon: string) {
      const dialogAddons = [ 'menu-addon', 'table-reservation-addon'] as const;
      const payloadAddons = ['sms-addon', 'autoconnect-addon', 'token-purchase-addon', 'queue', 'survey', 'hms', 'web-app-login', 'camera'] as const;

      if (dialogAddons.includes(addon as typeof dialogAddons[number])) {
        this.addOnToDeactivate = this.getAddonKey(addon);
        this.toggleCancellationDialog(true);
        return;
      }

      if (payloadAddons.includes(addon as typeof payloadAddons[number])) {
        const payload = this.getPayloadForAddon(addon);
        this.deactivateAddonRequest(payload);
      }
    },
    getAddonKey(addon: string): string {
      const addonKeyMap: { [key: string]: string } = {
        
        'menu-addon': 'menu',
        'table-reservation-addon': 'table',
      };
      return addonKeyMap[addon] || '';
    },
    getPayloadForAddon(addon: string): object {
      const payloadMap: { [key: string]: object } = {
        'sms-addon': {has_voucher_sms_feature_enabled: false},
        'autoconnect-addon': { has_autoconnect_addon_enabled: false },
        'token-purchase-addon': { has_token_purchase_addon_enabled: false },
        'queue': { has_queue_management_addon_enabled: false },
        'survey': { has_survey_addon_enabled: false },
        'hms': { has_hms_addon_enabled: false },
        'web-app-login': { has_web_app_login_enabled: false },
        'camera': { has_camera_solution_addon_enabled: false },
      };
      return payloadMap[addon] || {};
    },
    deactivateAddonRequest(payload: object) {
      instance
        .patch(`/partners/${this.loggedInUser.partner.id}/`, payload)
        .then(() => {
          this.deactivating = false;
          this.showSuccessToast('Add-On deactivated successfully');
          this.reloadPageAfterDelay();
        })
        .catch(() => {
          this.deactivating = false;
          this.showErrorToast('Could not deactivate the Add-On');
        });
    },
    showSuccessToast(message: string) {
      this.$toast.success(message, { position: 'top-right' });
    },
    showErrorToast(message: string) {
      this.$toast.error(message, { position: 'top-right' });
    },
    reloadPageAfterDelay() {
      setTimeout(() => window.location.reload(), 2000);
    },
    getVoucherPreference() {
      instance
        .get(`vouchers/automation-preference/?partner=${this.loggedInUser.partner.id}`)
        .then((response: { data: any }) => {
          if (response.data.duration) {
            this.autoconnectDailyUsage = response.data.autoconnect_daily_usage;
            this.duration = response.data.duration;
            if (response.data.custom_duration !== 0) this.customDuration = response.data.custom_duration;
          }
        })
        .catch((error: any) => {
          if (error.response?.status === 404) console.log('VOUCHER PREFERENCE NOT FOUND: ');
        });
    },
    setVoucherPreference() {
      if (
        this.loggedInUser.partner.voucher_sms_feature_enabled &&
        this.duration === 'Select Default Duration'
      ) {
        return this.$toast.warning('Select the default duration', { position: 'top-right' });
      }
      if (this.duration === 'custom' && !this.customDuration) {
        return this.$toast.warning('Define the custom duration in minutes', { position: 'top-right' });
      }
      this.loadingVoucherDurationPreference = true;
      const payload = {
        partner_id: this.loggedInUser.partner.id,
        default_duration: this.duration === 'Select Default Duration' ? null : this.duration,
        custom_duration: this.customDuration !== 0 ? this.customDuration : null,
        autoconnect_daily_usage: this.autoconnectDailyUsage,
      };
      instance
        .post('vouchers/automation-preference/', payload)
        .then(() => {
          this.loadingVoucherDurationPreference = false;
          this.$toast.success('Token duration preference updated', { position: 'top-right' });
        })
        .catch(() => {
          this.loadingVoucherDurationPreference = false;
          this.$toast.warning('Could not update the token duration preference', { position: 'top-right' });
        });
    },
    setTokenPurchasePreference() {
      if (
        this.loggedInUser.partner.voucher_sms_feature_enabled &&
        this.duration === 'Select Default Duration'
      ) {
        return this.$toast.warning('Select the default duration', { position: 'top-right' });
      }
      if (this.duration === 'custom' && !this.customDuration) {
        return this.$toast.warning('Define the custom duration in minutes', { position: 'top-right' });
      }
      this.loadingVoucherDurationPreference = true;
      const payload = {
        partner_id: this.loggedInUser.partner.id,
        default_duration: this.duration === 'Select Default Duration' ? null : this.duration,
        custom_duration: this.customDuration !== 0 ? this.customDuration : null,
      };
      instance
        .post('vouchers/automation-preference/', payload) // Fixed typo from 'automation-preferenced'
        .then(() => {
          this.loadingVoucherDurationPreference = false;
          this.$toast.success('Token duration preference updated', { position: 'top-right' });
        })
        .catch(() => {
          this.loadingVoucherDurationPreference = false;
          this.$toast.warning('Could not update the token duration preference', { position: 'top-right' });
        });
    },
  },
  created() {
    this.getVoucherPreference();
    this.fetchFoodDrinkMenus();
    this.fetchTokenPurchasePreferences();
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');
    if (tab) this.activeTab = tab;
  },
});
</script>

<style>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-btn:hover+.tooltip-text {
  visibility: visible;
  opacity: 1;
}

.dotonline {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}

.dotoffline {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}
</style>