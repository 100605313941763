<template>
  <div>
    <h2 class="text-xl font-bold mb-4">Revenue Insights Overview</h2>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <!-- Customer Churn Rate Chart -->
      <div class="chart-container">
        <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
        <apexchart
          v-else
          type="radialBar"
          :options="churnRateChartOptions"
          :series="churnRateChartData"
          height="250"
        ></apexchart>
      </div>

      <!-- Customer Conversion Rate Chart -->
      <div class="chart-container">
        <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
        <apexchart
          v-else
          type="area"
          :options="conversionRateChartOptions"
          :series="conversionRateChartData"
          height="250"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import ApexCharts from 'vue3-apexcharts';

export default {
  name: "CustomerInsightsChart",
  components: {
    apexchart: ApexCharts,
  },
  props: {
    insightAnalytics: {
      type: Object,
      required: true,
      default: () => ({ series: [], categories: [] })
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    duration: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      churnRateChartOptions: this.createChartOptions('Customer Churn Rate (%)', '#9B7FCB', 'radialBar'),
      conversionRateChartOptions: this.createChartOptions('Customer Conversion Rate (%)', '#FFB85C', 'area')
    };
  },
  computed: {
    chartSeries() {
      const series = this.isLoading || !this.insightAnalytics?.series ? [] : this.insightAnalytics.series.slice(6);
      console.log('Chart Series:', series);
      return series;
    },
    churnRateChartData() {
      const churnItem = this.chartSeries.find(s => s?.name === "Churn Rate");
      const value = churnItem && churnItem.data && churnItem.data[0] ? churnItem.data[0].churned : 0;
      console.log('Churn Rate Chart Data:', value);
      return [value];
    },
    conversionRateChartData() {
      const conversionItem = this.chartSeries.find(s => s?.name === "Conversion Rate");
      const categoriesLength = this.insightAnalytics?.categories?.length || 6; // Default to 6 if undefined
      const data = conversionItem && conversionItem.data ? conversionItem.data : Array(categoriesLength).fill(0);
      console.log('Conversion Rate Chart Data:', data);
      return [{
        name: 'Conversion Rate',
        data
      }];
    }
  },
  watch: {
    duration(newDuration) {
      this.updateChartOptions(newDuration);
    },
    insightAnalytics(newData) {
      if (newData && !this.isLoading) {
        this.updateChartOptions(this.duration);
      }
    }
  },
  methods: {
    createChartOptions(title, color, type = 'line') {
      let commonOptions = {
        chart: {
          height: 350,
        },
        title: {
          text: title,
          align: 'left',
        },
        xaxis: {
          categories: this.isLoading || !this.insightAnalytics?.categories ? [] : this.insightAnalytics.categories,
        },
        colors: [color],
      };

      if (type === 'area') {
        commonOptions.yaxis = {
          labels: {
            formatter: (value) => value.toFixed(2),
          },
        };
      } else if (type === 'radialBar') {
        commonOptions.plotOptions = {
          radialBar: {
            track: { background: '#e0e0e0', strokeWidth: '100%' },
            dataLabels: {
              name: { fontSize: '18px', color: '#000', fontWeight: 'bold' },
              value: { fontSize: '16px', color: '#000', fontWeight: 'bold' },
            },
          },
        };
        commonOptions.labels = ['Churn Rate'];
        delete commonOptions.xaxis;
      }

      return commonOptions;
    },

    updateChartOptions(newDuration) {
      const categories = this.isLoading || !this.insightAnalytics?.categories ? [] : this.insightAnalytics.categories;
      this.conversionRateChartOptions = { ...this.conversionRateChartOptions, xaxis: { categories } };
    }
  }
};
</script>
  <style scoped>
  .chart-container {
    background-color: #ffffff;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .chart-container:hover {
    transform: scale(1.05);
  }
  
  @media (max-width: 768px) {
    .chart-container {
      margin: 1rem 0;
    }
  }
  </style>
  