<template>
  <div class="ads-view-chart grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mt-4">
    <div v-if="isLoading" class="text-center col-span-full">Loading Charts...</div>
    <template v-else>
      <!-- New Viewers Chart -->
      <div class="chart-container">
        <apexchart
          :options="newViewersChartOptions"
          :series="newViewersChartData"
          type="bar"
          height="250"
        />
      </div>

      <!-- Repeated Viewers Chart -->
      <div class="chart-container">
        <apexchart
          :options="repeatedViewersChartOptions"
          :series="repeatedViewersChartData"
          type="bar"
          height="250"
        />
      </div>

      <!-- Total Views Chart -->
      <div class="chart-container">
        <apexchart
          :options="totalViewsChartOptions"
          :series="totalViewsChartData"
          type="bar"
          height="250"
        />
      </div>

      <!-- Average Views per Viewer Chart -->
      <div class="chart-container">
        <apexchart
          :options="avgViewsPerViewerChartOptions"
          :series="avgViewsPerViewerChartData"
          type="radialBar"
          height="250"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import ApexCharts from 'vue3-apexcharts';

// Define the interface for advertData
interface AdvertAnalyticsData {
  new_viewers: Record<string, number>;
  repeated_viewers: Record<string, number>;
}

// Define the shape of the chart series data
interface ChartSeries {
  name: string;
  data: number[];
}

export default defineComponent({
  name: 'AdViewAnalyticsChart',
  components: {
    apexchart: ApexCharts,
  },
  props: {
    duration: {
      type: String,
      required: true,
    },
    advertData: {
      type: Object as PropType<AdvertAnalyticsData>,
      default: () => ({ new_viewers: {}, repeated_viewers: {} }),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newViewersChartOptions: this.createChartOptions('New Viewers', '#FF8C8C', 'bar'),
      repeatedViewersChartOptions: this.createChartOptions('Repeated Viewers', '#FFD27F', 'bar'),
      totalViewsChartOptions: this.createChartOptions('Total Views', '#76C7C0', 'bar'),
      avgViewsPerViewerChartOptions: this.createChartOptions('Avg Views per Viewer', '#B6DC76', 'radialBar'),
    };
  },
  computed: {
    newViewersChartData(): ChartSeries[] {
      return this.transformAdvertData('new_viewers');
    },
    repeatedViewersChartData(): ChartSeries[] {
      return this.transformAdvertData('repeated_viewers');
    },
    totalViewsChartData(): ChartSeries[] {
      const categories = this.getXaxisCategories();
      const totalViews = Object.entries(this.advertData.new_viewers).map(([key, newViews]) => {
        const repeated = this.advertData.repeated_viewers[key] || 0;
        return newViews + repeated;
      });
      return [{
        name: 'Total Views',
        data: totalViews.length === categories.length ? totalViews : Array(categories.length).fill(0),
      }];
    },
    avgViewsPerViewerChartData(): ChartSeries[] {
      const totalViews =
        Object.values(this.advertData.new_viewers).reduce((sum, val) => sum + val, 0) +
        Object.values(this.advertData.repeated_viewers).reduce((sum, val) => sum + val, 0);
      const totalViewers = Object.values(this.advertData.new_viewers).reduce((sum, val) => sum + val, 0); // New viewers only
      const avg = totalViewers > 0 ? totalViews / totalViewers : 0;
      return [{
        name: 'Avg Views per Viewer',
        data: [parseFloat(avg.toFixed(2))],
      }];
    },
  },
  watch: {
    advertData: {
      handler() {
        this.updateChartOptions();
      },
      deep: true,
    },
    duration() {
      this.updateChartOptions();
    },
  },
  methods: {
    createChartOptions(title: string, color: string, chartType = 'bar'): any {
      return {
        chart: {
          type: chartType,
        },
        title: {
          text: title,
        },
        xaxis: {
          categories: this.getXaxisCategories(),
        },
        colors: [color],
        plotOptions: chartType === 'radialBar' ? {
          radialBar: {
            hollow: { margin: 5 },
            dataLabels: {
              name: { show: true },
              value: {
                show: true,
                formatter: (val: number) => val.toFixed(2),
              },
            },
          },
        } : {},
        noData: {
          text: 'No Data Available',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: { color: '#666', fontSize: '16px', fontFamily: 'Arial, sans-serif' },
        },
      };
    },
    getXaxisCategories(): string[] {
      const supportedDurations = ['6months', '12months', '1year', '5years', '3months', '1month'];
      if (supportedDurations.includes(this.duration)) {
        return Object.keys(this.advertData.new_viewers); // Use API-provided keys
      }

      const today = new Date();
      const categories: string[] = [];
      switch (this.duration) {
        case 'today':
          categories.push(today.toISOString().split('T')[0]);
          break;
        case 'week':
          for (let i = 6; i >= 0; i--) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);
            categories.push(date.toISOString().split('T')[0]);
          }
          break;
        default:
          return [];
      }
      return categories;
    },
    transformAdvertData(type: keyof AdvertAnalyticsData): ChartSeries[] {
      const categories = this.getXaxisCategories();
      const data = Object.values(this.advertData[type]);
      return [{
        name: type === 'new_viewers' ? 'New Viewers' : 'Repeated Viewers',
        data: categories.length === data.length ? data : Array(categories.length).fill(0),
      }];
    },
    updateChartOptions(): void {
      const categories = this.getXaxisCategories();
      this.newViewersChartOptions = { ...this.newViewersChartOptions, xaxis: { categories } };
      this.repeatedViewersChartOptions = { ...this.repeatedViewersChartOptions, xaxis: { categories } };
      this.totalViewsChartOptions = { ...this.totalViewsChartOptions, xaxis: { categories } };
      this.avgViewsPerViewerChartOptions = { ...this.avgViewsPerViewerChartOptions, xaxis: { categories: [] } }; // RadialBar doesn’t need categories
    },
  },
});
</script>

<style scoped>
.chart-container {
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.chart-container:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .chart-container {
    margin: 1rem 0;
  }
}
</style>