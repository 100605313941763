import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../assets/today.png'
import _imports_1 from '../../assets/plus-icon.png'


const _hoisted_1 = { class: "bg-[#DCE9E3] px-4 py-2 rounded-lg flex items-center shadow-sm" }
const _hoisted_2 = { class: "font-poppins font-medium text-sm md:text-base text-[#959CBD]" }
const _hoisted_3 = { class: "text-[#4E8D6D] font-semibold" }
const _hoisted_4 = { class: "w-full my-6" }
const _hoisted_5 = { class: "bg-gradient-to-br from-white to-[#F9FAFB] w-full min-h-[30rem] rounded-xl p-6 shadow-lg" }
const _hoisted_6 = { class: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "flex justify-between items-start mb-4" }
const _hoisted_9 = { class: "w-10 h-10 rounded-full flex items-center justify-center bg-[#DCE9E3] text-[#4E8D6D]" }
const _hoisted_10 = { class: "flex items-center gap-2" }
const _hoisted_11 = ["onClick", "disabled"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "font-poppins font-semibold text-lg text-[#464E5F] mb-2" }
const _hoisted_14 = { class: "text-[#6B7280] text-sm mb-4" }
const _hoisted_15 = { class: "relative flex flex-col h-[80vh]" }
const _hoisted_16 = { class: "flex-1 overflow-y-auto p-6" }
const _hoisted_17 = { class: "font-poppins font-bold text-2xl text-[#464E5F] mb-4" }
const _hoisted_18 = { class: "text-[#6B7280] text-sm mb-6" }
const _hoisted_19 = {
  key: 0,
  class: "mt-6"
}
const _hoisted_20 = { class: "flex flex-col sm:flex-row items-start sm:items-center gap-4" }
const _hoisted_21 = {
  key: 1,
  class: "mt-6"
}
const _hoisted_22 = { class: "mt-5 flex items-center gap-2" }
const _hoisted_23 = { class: "text-[#464E5F] capitalize" }
const _hoisted_24 = { class: "text-[#464E5F]" }
const _hoisted_25 = { class: "text-[#464E5F]" }
const _hoisted_26 = { class: "text-[#464E5F]" }
const _hoisted_27 = ["onClick"]
const _hoisted_28 = { class: "flex items-center" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = {
  key: 1,
  class: "text-center text-sm text-[#afafaf] mt-6"
}
const _hoisted_31 = {
  key: 2,
  class: "mt-6"
}
const _hoisted_32 = { class: "flex flex-col sm:flex-row items-start sm:items-center gap-4" }
const _hoisted_33 = { class: "flex items-center mt-4 gap-4" }
const _hoisted_34 = {
  key: 3,
  class: "mt-6"
}
const _hoisted_35 = { class: "grid grid-cols-1 md:grid-cols-2 gap-6" }
const _hoisted_36 = { class: "space-y-4" }
const _hoisted_37 = {
  key: 0,
  class: "flex flex-col gap-2"
}
const _hoisted_38 = { class: "flex items-center gap-2" }
const _hoisted_39 = ["onUpdate:modelValue"]
const _hoisted_40 = { class: "flex items-center gap-2" }
const _hoisted_41 = ["onUpdate:modelValue"]
const _hoisted_42 = { key: 1 }
const _hoisted_43 = { class: "text-[#464E5F] font-poppins font-medium text-sm" }
const _hoisted_44 = { class: "text-[#464E5F] font-poppins font-medium text-sm" }
const _hoisted_45 = {
  key: 4,
  class: "mt-6"
}
const _hoisted_46 = {
  key: 5,
  class: "mt-6"
}
const _hoisted_47 = {
  key: 6,
  class: "mt-6"
}
const _hoisted_48 = {
  key: 7,
  class: "mt-6"
}
const _hoisted_49 = { class: "space-y-4" }
const _hoisted_50 = {
  key: 0,
  class: "bg-gray-50 p-4 rounded-lg"
}
const _hoisted_51 = { class: "list-disc pl-5 text-sm text-[#6B7280]" }
const _hoisted_52 = ["onClick"]
const _hoisted_53 = {
  key: 1,
  class: "text-[#6B7280] text-sm"
}
const _hoisted_54 = { class: "bg-gray-50 p-4 rounded-lg" }
const _hoisted_55 = { class: "grid grid-cols-1 sm:grid-cols-2 gap-3" }
const _hoisted_56 = ["value", "onUpdate:modelValue"]
const _hoisted_57 = { class: "text-[#464E5F] font-poppins text-sm" }
const _hoisted_58 = {
  key: 8,
  class: "mt-6"
}
const _hoisted_59 = {
  key: 0,
  class: "p-6 border-t border-gray-200 bg-white flex justify-end space-x-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_SMSAddOn = _resolveComponent("SMSAddOn")!
  const _component_DashboardTableTitle = _resolveComponent("DashboardTableTitle")!
  const _component_ScaleLoader = _resolveComponent("ScaleLoader")!
  const _component_SmeTable = _resolveComponent("SmeTable")!
  const _component_activate_add_on = _resolveComponent("activate-add-on")!
  const _component_upload_menu = _resolveComponent("upload-menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardHeader, {
      title: "Shiriki Add-Ons",
      icon: "fa-solid fa-puzzle-piece",
      iconType: "class"
    }, {
      aside: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _cache[13] || (_cache[13] = _createTextVNode("Today: ")),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.today), 1)
          ]),
          _cache[14] || (_cache[14] = _createElementVNode("img", {
            src: _imports_0,
            alt: "africa",
            class: "w-5 h-5 ml-2 rounded-full"
          }, null, -1))
        ]),
        (_ctx.loggedInUser.partner.has_food_drink_menu_feature_enabled)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "bg-[#4E8D6D] px-5 py-2 rounded-lg flex items-center text-white font-poppins font-semibold text-sm md:text-base hover:bg-[#3d7054] transition-colors shadow-md",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showUploadMenuModal = true))
            }, _cache[15] || (_cache[15] = [
              _createElementVNode("img", {
                src: _imports_1,
                class: "w-4 h-4 mr-2",
                alt: ""
              }, null, -1),
              _createTextVNode(" Add Menu ")
            ])))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addons, (addon) => {
            return (_openBlock(), _createElementBlock("div", {
              key: addon.id,
              class: "bg-white rounded-xl p-6 shadow-md hover:shadow-lg hover:bg-gray-50 transition-all duration-300 cursor-pointer group border border-gray-100",
              onClick: ($event: any) => (_ctx.openPopup(addon.id))
            }, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("i", {
                    class: _normalizeClass([_ctx.getIcon(addon.icon), "text-lg"])
                  }, null, 2)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  (_ctx.isActive(addon.id))
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "h-7 px-2 text-xs border border-[#F24236] text-[#F24236] rounded-md font-poppins font-medium hover:bg-[#F24236] hover:text-white transition-colors",
                        onClick: _withModifiers(($event: any) => (_ctx.deactivateAddOn(addon.deactivateKey)), ["stop"]),
                        disabled: _ctx.deactivating
                      }, [
                        _cache[16] || (_cache[16] = _createElementVNode("i", { class: "fa-solid fa-power-off w-3 h-3 mr-1 inline" }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(_ctx.deactivating ? 'Deactivating...' : 'Deactivate'), 1)
                      ], 8, _hoisted_11))
                    : (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        class: "h-7 px-2 text-xs border border-[#4E8D6D] text-[#4E8D6D] rounded-md font-poppins font-medium hover:bg-[#4E8D6D] hover:text-white transition-colors",
                        onClick: _withModifiers(($event: any) => (_ctx.openPopup(addon.id)), ["stop"])
                      }, _cache[17] || (_cache[17] = [
                        _createElementVNode("i", { class: "fa-solid fa-bolt w-3 h-3 mr-1 inline" }, null, -1),
                        _createTextVNode(" Activate ")
                      ]), 8, _hoisted_12)),
                  _createElementVNode("span", {
                    class: _normalizeClass([
                'px-2 py-1 text-xs font-poppins font-medium rounded-full transition-all duration-300',
                _ctx.isActive(addon.id) ? 'bg-[#4E8D6D] text-white hover:bg-[#3d7054]' : 'bg-gray-200 text-gray-600'
              ])
                  }, _toDisplayString(_ctx.isActive(addon.id) ? 'Active' : 'Inactive'), 3)
                ])
              ]),
              _createElementVNode("h3", _hoisted_13, _toDisplayString(addon.name), 1),
              _createElementVNode("p", _hoisted_14, _toDisplayString(addon.description), 1),
              _cache[18] || (_cache[18] = _createStaticVNode("<div class=\"mt-4 pt-4 border-t border-gray-200 flex justify-between items-center\"><div class=\"text-xs text-[#6B7280]\"><span class=\"font-medium\"></span></div><div class=\"w-8 h-8 rounded-full flex items-center justify-center bg-[#F3F6F9] text-[#4E8D6D] group-hover:bg-[#4E8D6D] group-hover:text-white transition-colors duration-300\"><i class=\"fa-solid fa-plus w-4 h-4\"></i></div></div>", 1))
            ], 8, _hoisted_7))
          }), 128))
        ])
      ])
    ]),
    (_ctx.showPopup)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 px-4 transition-all duration-500 ease-out", { 'bg-opacity-10': !_ctx.showPopup }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["fixed inset-x-0 top-1/2 -translate-y-1/2 max-w-2xl mx-auto bg-white rounded-xl p-0 overflow-hidden shadow-lg transition-all duration-500 ease-out", { 'opacity-100 scale-100': _ctx.showPopup, 'opacity-0 scale-95 pointer-events-none': !_ctx.showPopup }])
          }, [
            _createElementVNode("div", _hoisted_15, [
              _cache[49] || (_cache[49] = _createElementVNode("div", { class: "w-full h-2 bg-[#4E8D6D]" }, null, -1)),
              _createElementVNode("button", {
                class: "absolute top-4 right-4 p-1 rounded-full bg-gray-200 text-gray-600 hover:bg-[#F24236] hover:text-white transition-colors",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
              }, _cache[19] || (_cache[19] = [
                _createElementVNode("i", { class: "fa-solid fa-xmark w-4 h-4" }, null, -1)
              ])),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("h2", _hoisted_17, _toDisplayString(_ctx.selectedAddonObj.name), 1),
                _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.selectedAddonObj.description), 1),
                (_ctx.selectedAddon === 'sms-package' && _ctx.loggedInUser.partner.has_voucher_sms_feature_enabled)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createVNode(_component_SMSAddOn),
                      _cache[22] || (_cache[22] = _createElementVNode("h3", { class: "font-poppins font-semibold text-lg text-[#4E8D6D] mb-3" }, "SMS Token Duration", -1)),
                      _cache[23] || (_cache[23] = _createElementVNode("p", { class: "text-[#6B7280] text-sm mb-4" }, " The voucher SMS feature lets users request a WiFi token via SMS, instantly connecting them without needing manual token distribution... ", -1)),
                      _createElementVNode("div", _hoisted_20, [
                        _cache[21] || (_cache[21] = _createElementVNode("label", { class: "font-poppins text-sm font-medium text-[#464E5F]" }, "Token Duration:", -1)),
                        _withDirectives(_createElementVNode("select", {
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.duration) = $event)),
                          class: "w-full sm:w-48 h-10 border border-gray-300 rounded-md px-3 text-sm focus:ring-2 focus:ring-[#4E8D6D] focus:border-[#4E8D6D] outline-none transition-all"
                        }, _cache[20] || (_cache[20] = [
                          _createStaticVNode("<option value=\"Select Default Duration\" disabled selected>Select Default Duration</option><option value=\"1_hour\">1 Hour</option><option value=\"2_hours\">2 Hours</option><option value=\"5_hours\">5 Hours</option><option value=\"10_hours\">10 Hours</option><option value=\"24_hours\">24 Hours</option><option value=\"unlimited\">Unlimited</option><option value=\"custom\">Custom Minutes</option>", 8)
                        ]), 512), [
                          [_vModelSelect, _ctx.duration]
                        ]),
                        (_ctx.duration === 'custom')
                          ? _withDirectives((_openBlock(), _createElementBlock("input", {
                              key: 0,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customDuration) = $event)),
                              type: "number",
                              placeholder: "Minutes",
                              class: "w-full sm:w-24 h-10 border border-gray-300 rounded-md px-3 text-sm focus:ring-2 focus:ring-[#4E8D6D] focus:border-[#4E8D6D] outline-none transition-all"
                            }, null, 512)), [
                              [_vModelText, _ctx.customDuration]
                            ])
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : (_ctx.selectedAddon === 'food-drink-menu' && _ctx.loggedInUser.partner.has_food_drink_menu_feature_enabled)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        (_ctx.propLoaded)
                          ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
                              key: 0,
                              perPageItems: _ctx.perSize,
                              pageNumber: _ctx.page,
                              ApiData: _ctx.foodDrinkMenus,
                              onHandleNext: _ctx.handlleNext,
                              onHandlePrev: _ctx.handlePrev
                            }, {
                              section: _withCtx(() => [
                                _createElementVNode("div", _hoisted_22, [
                                  _cache[25] || (_cache[25] = _createElementVNode("span", { class: "text-[#B5B5C3] font-poppins font-medium text-sm" }, "Rows per page", -1)),
                                  _withDirectives(_createElementVNode("select", {
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedOption) = $event)),
                                    onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args))),
                                    class: "bg-[#F3F6F9] h-8 px-2 rounded-lg text-[#464E5F] font-poppins font-semibold text-sm focus:ring-2 focus:ring-[#4E8D6D] outline-none"
                                  }, _cache[24] || (_cache[24] = [
                                    _createElementVNode("option", { value: "10" }, "10", -1),
                                    _createElementVNode("option", { value: "20" }, "20", -1),
                                    _createElementVNode("option", { value: "30" }, "30", -1),
                                    _createElementVNode("option", { value: "40" }, "40", -1),
                                    _createElementVNode("option", { value: "50" }, "50", -1)
                                  ]), 544), [
                                    [_vModelSelect, _ctx.selectedOption]
                                  ])
                                ])
                              ]),
                              _: 1
                            }, 8, ["perPageItems", "pageNumber", "ApiData", "onHandleNext", "onHandlePrev"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_SmeTable, { tableHeaderData: _ctx.foodDrinkMenuTableHeaders }, {
                          tableBodyData: _withCtx(() => [
                            (_ctx.foodDrinkMenus.length)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.foodDrinkMenus, (menu) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: menu.id,
                                    class: "flex items-center justify-between py-3 px-4 bg-gray-50 rounded-lg my-2 text-sm text-[#B5B5C3] font-poppins font-medium hover:bg-gray-100 transition-colors"
                                  }, [
                                    _createElementVNode("span", _hoisted_23, _toDisplayString(menu.menu_type), 1),
                                    _createElementVNode("span", _hoisted_24, _toDisplayString(menu.realm.name), 1),
                                    _createElementVNode("span", _hoisted_25, _toDisplayString(menu.original_file_name), 1),
                                    _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.createdAt(menu.created)), 1),
                                    _createElementVNode("span", {
                                      onClick: ($event: any) => (_ctx.downloadFile(menu.file)),
                                      class: "p-2 bg-[#4E8D6D] text-white rounded-md cursor-pointer hover:bg-[#3d7054] transition-colors"
                                    }, _cache[26] || (_cache[26] = [
                                      _createTextVNode(" Download "),
                                      _createElementVNode("i", { class: "ml-1 fa-solid fa-download" }, null, -1)
                                    ]), 8, _hoisted_27),
                                    _createElementVNode("div", _hoisted_28, [
                                      (!_ctx.deleting || _ctx.currentDeletionFileID !== menu.id)
                                        ? (_openBlock(), _createElementBlock("span", {
                                            key: 0,
                                            onClick: ($event: any) => (_ctx.deleteMenu(menu.id)),
                                            class: "p-2 bg-[#F24236] text-white rounded-md cursor-pointer hover:bg-[#d32f2f] transition-colors"
                                          }, " Delete ", 8, _hoisted_29))
                                        : _createCommentVNode("", true),
                                      (_ctx.deleting && _ctx.currentDeletionFileID === menu.id)
                                        ? (_openBlock(), _createBlock(_component_ScaleLoader, {
                                            key: 1,
                                            color: "#d7191c"
                                          }))
                                        : _createCommentVNode("", true)
                                    ])
                                  ]))
                                }), 128))
                              : (_openBlock(), _createElementBlock("div", _hoisted_30, _cache[27] || (_cache[27] = [
                                  _createElementVNode("b", null, "No Food & Drinks Menus Found. Click \"Add Menu\" to get started.", -1)
                                ])))
                          ]),
                          _: 1
                        }, 8, ["tableHeaderData"])
                      ]))
                    : (_ctx.selectedAddon === 'autoconnect' && _ctx.loggedInUser.partner.has_autoconnect_addon_enabled)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                          _cache[31] || (_cache[31] = _createElementVNode("h3", { class: "font-poppins font-semibold text-lg text-[#4E8D6D] mb-3" }, "Auto-Connect Token Duration", -1)),
                          _cache[32] || (_cache[32] = _createElementVNode("p", { class: "text-[#6B7280] text-sm mb-4" }, " Our Auto-Connect Token Duration feature automatically generates a token when the user connects... ", -1)),
                          _createElementVNode("div", _hoisted_32, [
                            _cache[29] || (_cache[29] = _createElementVNode("label", { class: "font-poppins text-sm font-medium text-[#464E5F]" }, "Token Duration:", -1)),
                            _withDirectives(_createElementVNode("select", {
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.duration) = $event)),
                              class: "w-full sm:w-48 h-10 border border-gray-300 rounded-md px-3 text-sm focus:ring-2 focus:ring-[#4E8D6D] focus:border-[#4E8D6D] outline-none transition-all"
                            }, _cache[28] || (_cache[28] = [
                              _createStaticVNode("<option value=\"Select Default Duration\" disabled selected>Select Default Duration</option><option value=\"1_hour\">1 Hour</option><option value=\"2_hours\">2 Hours</option><option value=\"5_hours\">5 Hours</option><option value=\"10_hours\">10 Hours</option><option value=\"24_hours\">24 Hours</option><option value=\"custom\">Custom Minutes</option>", 7)
                            ]), 512), [
                              [_vModelSelect, _ctx.duration]
                            ]),
                            (_ctx.duration === 'custom')
                              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                                  key: 0,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.customDuration) = $event)),
                                  type: "number",
                                  placeholder: "Minutes",
                                  class: "w-full sm:w-24 h-10 border border-gray-300 rounded-md px-3 text-sm focus:ring-2 focus:ring-[#4E8D6D] focus:border-[#4E8D6D] outline-none transition-all"
                                }, null, 512)), [
                                  [_vModelText, _ctx.customDuration]
                                ])
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_33, [
                            _cache[30] || (_cache[30] = _createElementVNode("label", {
                              for: "autoconnect-daily-usage",
                              class: "font-poppins text-sm font-medium text-[#464E5F]"
                            }, "Daily Usage Limit:", -1)),
                            _withDirectives(_createElementVNode("input", {
                              type: "checkbox",
                              id: "autoconnect-daily-usage",
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.autoconnectDailyUsage) = $event)),
                              class: "w-5 h-5 text-[#4E8D6D] rounded focus:ring-[#4E8D6D]"
                            }, null, 512), [
                              [_vModelCheckbox, _ctx.autoconnectDailyUsage]
                            ])
                          ]),
                          _cache[33] || (_cache[33] = _createElementVNode("p", { class: "text-[#6B7280] text-sm mt-2" }, "Enabling this limits daily free internet access to the specified duration.", -1))
                        ]))
                      : (_ctx.selectedAddon === 'token-purchase' && _ctx.loggedInUser.partner.has_token_purchase_addon_enabled)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                            _cache[40] || (_cache[40] = _createElementVNode("h3", { class: "font-poppins font-semibold text-lg text-[#4E8D6D] mb-3" }, "Token Purchase Add-On", -1)),
                            _cache[41] || (_cache[41] = _createElementVNode("p", { class: "text-[#6B7280] text-sm mb-4" }, " Enhance your captive portal with our Token Purchase Addon... ", -1)),
                            _createElementVNode("div", _hoisted_35, [
                              _cache[39] || (_cache[39] = _createStaticVNode("<div class=\"space-y-4\"><div><p class=\"text-[#464E5F] font-poppins font-medium text-sm\"><strong>Free Access Configuration:</strong> Administrators can set a default duration... </p></div><div><p class=\"text-[#464E5F] font-poppins font-medium text-sm\"><strong>Daily Free Access Restriction:</strong> Free access is limited to a daily basis... </p></div><div><p class=\"text-[#464E5F] font-poppins font-medium text-sm\"><strong>Token Purchase:</strong> Once the free access period expires... </p></div><div><p class=\"text-[#464E5F] font-poppins font-medium text-sm\"><strong>User Experience:</strong> When users connect to the WiFi network... </p></div></div>", 1)),
                              _createElementVNode("div", null, [
                                _cache[38] || (_cache[38] = _createElementVNode("h4", { class: "font-poppins font-semibold text-md text-[#4E8D6D] mb-3" }, "Token Durations and Prices", -1)),
                                _createElementVNode("div", _hoisted_36, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tokenPurchaseOptions, (option, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: index,
                                      class: "bg-gray-50 p-4 rounded-lg shadow-sm hover:bg-gray-100 transition-colors duration-200"
                                    }, [
                                      (_ctx.editMode)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                                            _createElementVNode("div", _hoisted_38, [
                                              _cache[34] || (_cache[34] = _createElementVNode("label", { class: "font-poppins text-sm font-medium text-[#464E5F]" }, "Price (RWF):", -1)),
                                              _withDirectives(_createElementVNode("input", {
                                                "onUpdate:modelValue": ($event: any) => ((option.price) = $event),
                                                type: "text",
                                                class: "w-full h-8 border border-gray-300 rounded-md px-2 text-sm focus:ring-2 focus:ring-[#4E8D6D] outline-none"
                                              }, null, 8, _hoisted_39), [
                                                [_vModelText, option.price]
                                              ])
                                            ]),
                                            _createElementVNode("div", _hoisted_40, [
                                              _cache[35] || (_cache[35] = _createElementVNode("label", { class: "font-poppins text-sm font-medium text-[#464E5F]" }, "Duration (minutes):", -1)),
                                              _withDirectives(_createElementVNode("input", {
                                                "onUpdate:modelValue": ($event: any) => ((option.duration_in_minutes) = $event),
                                                type: "number",
                                                class: "w-full h-8 border border-gray-300 rounded-md px-2 text-sm focus:ring-2 focus:ring-[#4E8D6D] outline-none"
                                              }, null, 8, _hoisted_41), [
                                                [_vModelText, option.duration_in_minutes]
                                              ])
                                            ])
                                          ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_42, [
                                            _createElementVNode("p", _hoisted_43, [
                                              _cache[36] || (_cache[36] = _createElementVNode("strong", null, "Price (RWF):", -1)),
                                              _createTextVNode(" " + _toDisplayString(option.price), 1)
                                            ]),
                                            _createElementVNode("p", _hoisted_44, [
                                              _cache[37] || (_cache[37] = _createElementVNode("strong", null, "Duration:", -1)),
                                              _createTextVNode(" " + _toDisplayString(option.display_duration), 1)
                                            ])
                                          ]))
                                    ]))
                                  }), 128))
                                ]),
                                _createElementVNode("button", {
                                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.editMode = !_ctx.editMode)),
                                  class: "mt-4 bg-[#4E8D6D] text-white font-poppins font-medium text-sm rounded-md px-4 py-2 hover:bg-[#3d7054] transition-colors"
                                }, _toDisplayString(_ctx.editMode ? 'Cancel' : 'Edit'), 1)
                              ])
                            ])
                          ]))
                        : (_ctx.selectedAddon === 'queue' && _ctx.loggedInUser.partner.has_queue_management_addon_enabled)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_45, _cache[42] || (_cache[42] = [
                              _createElementVNode("h3", { class: "font-poppins font-semibold text-lg text-[#4E8D6D] mb-3" }, "Queue System", -1),
                              _createElementVNode("p", { class: "text-[#6B7280] text-sm mb-4" }, "Our Smart Queue Management System streamlines customer flow by organizing and optimizing wait times...", -1)
                            ])))
                          : (_ctx.selectedAddon === 'survey' && _ctx.loggedInUser.partner.has_survey_addon_enabled)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_46, _cache[43] || (_cache[43] = [
                                _createElementVNode("h3", { class: "font-poppins font-semibold text-lg text-[#4E8D6D] mb-3" }, "Survey Add-On", -1),
                                _createElementVNode("p", { class: "text-[#6B7280] text-sm mb-4" }, "Create surveys, share them instantly, and view live responses to gather valuable feedback...", -1)
                              ])))
                            : (_ctx.selectedAddon === 'hms' && _ctx.loggedInUser.partner.has_hms_addon_enabled)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_47, _cache[44] || (_cache[44] = [
                                  _createElementVNode("h3", { class: "font-poppins font-semibold text-lg text-[#4E8D6D] mb-3" }, "HMS Add-On", -1),
                                  _createElementVNode("p", { class: "text-[#6B7280] text-sm mb-4" }, "Process orders, track inventory, and analyze data to streamline restaurant workflow.", -1)
                                ])))
                              : (_ctx.selectedAddon === 'web-app-login' && _ctx.loggedInUser.partner.has_web_app_login_enabled)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                                    _cache[47] || (_cache[47] = _createElementVNode("h3", { class: "font-poppins font-semibold text-lg text-[#4E8D6D] mb-3" }, "Web App Login", -1)),
                                    _cache[48] || (_cache[48] = _createElementVNode("p", { class: "text-[#6B7280] text-sm mb-4" }, " This feature is enabled by default, requiring users to log in to access the web app. Select the information you want to collect from users below, then click \"Save\". Deactivate to disable the login requirement. ", -1)),
                                    _createElementVNode("div", _hoisted_49, [
                                      (_ctx.selectedFields.length)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                                            _cache[45] || (_cache[45] = _createElementVNode("h4", { class: "font-poppins font-medium text-md text-[#464E5F] mb-3" }, "Selected Fields:", -1)),
                                            _createElementVNode("ul", _hoisted_51, [
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedFields, (field) => {
                                                return (_openBlock(), _createElementBlock("li", {
                                                  key: field,
                                                  class: "flex justify-between items-center py-1"
                                                }, [
                                                  _createElementVNode("span", null, _toDisplayString(field), 1),
                                                  _createElementVNode("button", {
                                                    onClick: ($event: any) => (_ctx.removeField(field)),
                                                    class: "text-[#F24236] hover:text-[#d32f2f] text-xs font-medium"
                                                  }, " Remove ", 8, _hoisted_52)
                                                ]))
                                              }), 128))
                                            ])
                                          ]))
                                        : (_openBlock(), _createElementBlock("p", _hoisted_53, "No fields selected yet.")),
                                      _createElementVNode("div", _hoisted_54, [
                                        _cache[46] || (_cache[46] = _createElementVNode("h4", { class: "font-poppins font-medium text-md text-[#464E5F] mb-3" }, "Available Fields:", -1)),
                                        _createElementVNode("div", _hoisted_55, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableFields, (field) => {
                                            return (_openBlock(), _createElementBlock("label", {
                                              key: field,
                                              class: "flex items-center gap-2"
                                            }, [
                                              _withDirectives(_createElementVNode("input", {
                                                type: "checkbox",
                                                value: field,
                                                "onUpdate:modelValue": ($event: any) => ((_ctx.loggedInUser.partner[_ctx.fieldToKey[field]]) = $event),
                                                class: "w-5 h-5 text-[#4E8D6D] rounded focus:ring-[#4E8D6D]"
                                              }, null, 8, _hoisted_56), [
                                                [_vModelCheckbox, _ctx.loggedInUser.partner[_ctx.fieldToKey[field]]]
                                              ]),
                                              _createElementVNode("span", _hoisted_57, _toDisplayString(field), 1)
                                            ]))
                                          }), 128))
                                        ])
                                      ])
                                    ])
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_58, [
                                    _createVNode(_component_activate_add_on, {
                                      noActiveAddOnTitle: `${_ctx.selectedAddonObj.name} Inactive`,
                                      noActiveAddOnMessage: _ctx.selectedAddonObj.description,
                                      processingAddOnActivation: _ctx.processingAddOnActivation,
                                      price: 0,
                                      onActivateAddOn: _ctx.activateSelectedAddon
                                    }, null, 8, ["noActiveAddOnTitle", "noActiveAddOnMessage", "processingAddOnActivation", "onActivateAddOn"])
                                  ]))
              ]),
              (_ctx.isActive(_ctx.selectedAddonObj.id))
                ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                    _createElementVNode("button", {
                      class: "border border-gray-300 text-[#464E5F] font-poppins font-medium text-sm rounded-md px-6 py-2 hover:bg-gray-100 transition-colors",
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.deactivateAddOn(_ctx.selectedAddonObj.deactivateKey)))
                    }, " Deactivate "),
                    _createElementVNode("button", {
                      class: "bg-[#4E8D6D] text-white font-poppins font-medium text-sm rounded-md px-6 py-2 hover:bg-[#3d7054] transition-colors",
                      onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.saveOrActivate && _ctx.saveOrActivate(...args)))
                    }, " Save ")
                  ]))
                : _createCommentVNode("", true)
            ])
          ], 2)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.showUploadMenuModal)
      ? (_openBlock(), _createBlock(_component_upload_menu, {
          key: 1,
          onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.closeModal()))
        }))
      : _createCommentVNode("", true)
  ], 64))
}