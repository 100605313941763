import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "ads-view-chart grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mt-4" }
const _hoisted_2 = {
  key: 0,
  class: "text-center col-span-full"
}
const _hoisted_3 = { class: "chart-container" }
const _hoisted_4 = { class: "chart-container" }
const _hoisted_5 = { class: "chart-container" }
const _hoisted_6 = { class: "chart-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Loading Charts..."))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_apexchart, {
              options: _ctx.newViewersChartOptions,
              series: _ctx.newViewersChartData,
              type: "bar",
              height: "250"
            }, null, 8, ["options", "series"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_apexchart, {
              options: _ctx.repeatedViewersChartOptions,
              series: _ctx.repeatedViewersChartData,
              type: "bar",
              height: "250"
            }, null, 8, ["options", "series"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_apexchart, {
              options: _ctx.totalViewsChartOptions,
              series: _ctx.totalViewsChartData,
              type: "bar",
              height: "250"
            }, null, 8, ["options", "series"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_apexchart, {
              options: _ctx.avgViewsPerViewerChartOptions,
              series: _ctx.avgViewsPerViewerChartData,
              type: "radialBar",
              height: "250"
            }, null, 8, ["options", "series"])
          ])
        ], 64))
  ]))
}