<template>
  <div>
    <div class="flex flex-col sm:flex-row">
      <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 shadow m-2 p-3">
        <h1 class="text-sm border-b border-solid border-blue-500">Overall Average Rating</h1>
        <h1 class="text-center text-7xl font-extrabold mt-16">
          {{ overall_average_rating }}<i class="fa-solid fa-star text-5xl relative top-[-8px]"></i>
        </h1>
        <p class="text-sm text-center mb-16">{{ total_ratings }} Total Ratings</p>
      </div>
      <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 shadow m-2 p-3" style="min-height: 205px;">
        <h1 class="text-sm border-b border-solid border-blue-500 mb-3">Distribution of Ratings</h1>
        <canvas id="ratingDistributionChart"></canvas>
      </div>
    </div>

    <div class="flex flex-col sm:flex-row">
      <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 shadow m-2 p-3" style="min-height: 205px;">
        <h1 class="text-sm border-b border-solid border-blue-500 mb-3">Trends Over Time</h1>
        <canvas id="trendsOverTimeChart" class="mt-2"></canvas>
      </div>
      <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 shadow m-2 p-3" style="min-height: 205px;">
        <h1 class="text-sm border-b border-solid border-blue-500 mb-3">Feedback Frequency</h1>
        <canvas id="feedbackFrequencyChart" class="mt-2"></canvas>
      </div>
    </div>

    <div class="w-full shadow m-2 p-3">
      <SmeTable :tableHeaderData="feedbackTableHeaders">
        <template v-slot:tableBodyData>
          <template v-if="feedbackList.length">
            <div v-for="feedback in filterFeedbackData()" 
                :key="feedback.id"
                class="text-sm table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">
              <td>
                <div class="flex items-center">
                  <div class="flex flex-col ml-3">
                    <span class="text-sm capitalize text-[#464E5F] mb-0.5">{{ feedback.rating }}</span>
                  </div>
                </div>
              </td>
              <td>
                <span class="text-sm text-[#464E5F]">{{ feedback.comment }}</span>
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="text-sm capitalize text-[#464E5F] mb-0.5">{{ feedback.created }}</span>
                </div>
              </td>
            </div>
          </template>
          <div v-else class="w-full text-center text-[12px] text-[#afafaf] mt-4">
            <b>No feedback found</b>
          </div>
        </template>
      </SmeTable>

      <div v-if="loadingFeedback" class="spinner-container">
        <div class="spinner"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Chart from 'chart.js/auto'
import instance from '../../axios-interceptor'
import SmeTable from '@/components/reusable/SmeTable.vue'

export default {
  name: 'OverallRating',
  components: {
    SmeTable
  },
  props: {
    duration: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      total_ratings: 0,
      overall_average_rating: 0.0,
      feedbackFrequencyChart: null,
      trendsOverTimeChart: null,
      ratingDistributionChart: null,
      feedbackList: [],
      feedbackTableHeaders: [
        'Rating',
        'Comment',
        'Created At'
      ],
      loadingFeedback: false,
      searchQuery: ''
    }
  },
  computed: {
    ...mapGetters(['loggedInUser'])
  },
  watch: {
    duration: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    getDates() {
      const today = new Date()
      let endDate = today.toISOString().split('T')[0]
      let startDate

      switch (this.duration) {
        case 'today':
          startDate = endDate
          break
        case '1week':
          startDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
          break
        case '1month':
          startDate = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
          break
        case '3months':
          startDate = new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
          break
        case '6months':
          startDate = new Date(today.getTime() - 180 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
          break
        case '12months':
          startDate = new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
          break
        default:
          startDate = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
      }

      return { startDate, endDate }
    },

    async fetchData() {
      const { startDate, endDate } = this.getDates()
      this.loadingFeedback = true

      try {
        const response = await instance.get(
          `rating/?partner_id=${this.loggedInUser.partner.id}&start_date=${startDate}&end_date=${endDate}`
        )
        this.feedbackList = response.data

        const analyticsResponse = await instance.get(
          'rating/analytics/?partner=' + this.loggedInUser.partner.id +
          '&start_date=' + startDate +
          '&end_date=' + endDate
        )

        this.total_ratings = analyticsResponse.data.total_ratings
        this.overall_average_rating = analyticsResponse.data.overall_average_rating.toFixed(2)

        this.createOrUpdateRatingDistributionChart(
          analyticsResponse.data.distribution_of_ratings.labels,
          analyticsResponse.data.distribution_of_ratings.data
        )
        this.createOrUpdateTrendsOverTimeChart(
          analyticsResponse.data.trends_over_time.labels,
          analyticsResponse.data.trends_over_time.data
        )
        this.createOrUpdateFeedbackFrequencyChart(
          analyticsResponse.data.feedback_frequency.labels,
          analyticsResponse.data.feedback_frequency.data
        )
      } catch (error) {
        console.error('Error fetching feedback data:', error)
      } finally {
        this.loadingFeedback = false
      }
    },

    filterFeedbackData() {
      if (!this.searchQuery) {
        return this.feedbackList
      }

      const query = this.searchQuery.toLowerCase()
      return this.feedbackList.filter((item) => {
        const rating = item.rating.toString().toLowerCase()
        const comment = item.comment.toString().toLowerCase()
        const createdAt = item.created.toString().toLowerCase()

        return rating.includes(query) ||
          comment.includes(query) ||
          createdAt.includes(query)
      })
    },

    createOrUpdateFeedbackFrequencyChart(rlabels, rdata) {
      const feedbackFrequencyChartInstance = document.getElementById('feedbackFrequencyChart')
      if (this.feedbackFrequencyChart)
        this.feedbackFrequencyChart.destroy()

      this.feedbackFrequencyChart = new Chart(feedbackFrequencyChartInstance, {
        type: 'bar',
        data: {
          labels: rlabels,
          datasets: [{
            axis: 'y',
            data: rdata,
            fill: false,
            backgroundColor: 'rgba(154, 208, 245, 0.8)'
          }]
        },
        options: {
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      })
    },

    createOrUpdateTrendsOverTimeChart(rlabels, rdata) {
      const trendsOverTimeChartInstance = document.getElementById('trendsOverTimeChart')
      if (this.trendsOverTimeChart)
        this.trendsOverTimeChart.destroy()

      this.trendsOverTimeChart = new Chart(trendsOverTimeChartInstance, {
        type: 'line',
        data: {
          labels: rlabels,
          datasets: [{
            axis: 'y',
            data: rdata,
            fill: false,
            borderColor: 'rgba(255, 102, 0, 0.8)',
            backgroundColor: 'rgba(255, 0, 0, 1)'
          }]
        },
        options: {
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      })
    },

    createOrUpdateRatingDistributionChart(rlabels, rdata) {
      const ratingDistributionChartInstance = document.getElementById('ratingDistributionChart')

      if (this.ratingDistributionChart)
        this.ratingDistributionChart.destroy()

      this.ratingDistributionChart = new Chart(ratingDistributionChartInstance, {
        type: 'bar',
        data: {
          labels: ['\u2605', '\u2605\u2605', '\u2605\u2605\u2605', '\u2605\u2605\u2605\u2605', '\u2605\u2605\u2605\u2605\u2605'],
          datasets: [{
            axis: 'y',
            data: rdata,
            fill: false,
            backgroundColor: 'rgba(255, 102, 0, 0.8)'
          }]
        },
        options: {
          indexAxis: 'y',
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      })
    }
  }
}
</script>