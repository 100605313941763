<template>
  <div class="sms-insights-chart grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mt-4">
    <!-- SMS Sent Chart -->
    <div class="chart-container">
      <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
      <apexchart
        v-else
        :options="smsSentChartOptions"
        :series="smsSentChartData"
        type="bar"
        height="250"
      />
    </div>

    <!-- SMS Failed Chart -->
    <div class="chart-container">
      <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
      <apexchart
        v-else
        :options="smsFailedChartOptions"
        :series="smsFailedChartData"
        type="bar"
        height="250"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import ApexCharts from 'vue3-apexcharts';

// Interface for SMS analytics data
interface SMSAnalyticsData {
  sent_sms: Record<string, number>;
  failed_sms: Record<string, number>;
  response_rate?: Record<string, number>;
  avg_response_time?: Record<string, number>;
  opt_out_rate?: Record<string, number>;
}

// Interface for chart series data
interface ChartSeries {
  name: string;
  data: number[];
}

export default defineComponent({
  name: 'SmsInsightsChart',
  components: {
    apexchart: ApexCharts,
  },
  props: {
    duration: {
      type: String,
      required: true,
    },
    smsData: {
      type: Object as PropType<SMSAnalyticsData>,
      default: () => ({ sent_sms: {}, failed_sms: {} }),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      smsSentChartOptions: this.createChartOptions('SMS Sent', '#E39191', 'bar'),
      smsFailedChartOptions: this.createChartOptions('SMS Failed', '#76C7C0', 'bar'),
      smsResponseRateChartOptions: this.createChartOptions('SMS Response Rate', '#FFD27F', 'line'),
      avgResponseTimeChartOptions: this.createChartOptions('Average Response Time', '#B18CD9', 'area'),
      smsOptOutRateChartOptions: this.createChartOptions('SMS Opt-Out Rate', '#FF8C8C', 'bar'),
    };
  },
  computed: {
    smsSentChartData(): ChartSeries[] {
      return this.transformSMSData('sent_sms');
    },
    smsFailedChartData(): ChartSeries[] {
      return this.transformSMSData('failed_sms');
    },
    smsResponseRateChartData(): ChartSeries[] {
      return this.transformSMSData('response_rate');
    },
    avgResponseTimeChartData(): ChartSeries[] {
      return this.transformSMSData('avg_response_time');
    },
    smsOptOutRateChartData(): ChartSeries[] {
      return this.transformSMSData('opt_out_rate');
    },
    hasSmsData(): boolean {
      return (
        Object.values(this.smsData.sent_sms).some((value) => value > 0) ||
        Object.values(this.smsData.failed_sms).some((value) => value > 0)
      );
    },
  },
  watch: {
    smsData: {
      handler() {
        this.updateChartOptions();
      },
      deep: true,
    },
    duration() {
      this.updateChartOptions();
    },
  },
  methods: {
    createChartOptions(title: string, color: string, type: string): any {
      return {
        chart: { type },
        title: { text: title },
        xaxis: { categories: this.getXaxisCategories() },
        colors: [color],
        noData: {
          text: this.hasSmsData ? undefined : `No ${title} Data`,
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#666',
            fontSize: '16px',
            fontFamily: 'Arial, sans-serif',
          },
        },
      };
    },
    getXaxisCategories(): string[] {
      const supportedDurations = ['6months', '12months', '1year', '5years', '3months', '1month'];
      if (supportedDurations.includes(this.duration)) {
        return Object.keys(this.smsData.sent_sms); // Use API months directly
      }

      const categories: string[] = [];
      const today = new Date();
      switch (this.duration) {
        case 'today':
          categories.push('Today');
          break;
        case 'week':
          for (let i = 6; i >= 0; i--) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);
            categories.push(this.formatDate(date));
          }
          break;
        default:
          return [];
      }
      return categories;
    },
    formatDate(date: Date): string {
      return `${date.getDate()}/${date.getMonth() + 1}`;
    },
    transformSMSData(type: keyof SMSAnalyticsData): ChartSeries[] {
      const categories = this.getXaxisCategories();
      const dataValues = this.smsData[type] ?? {}; // Fallback to empty object if undefined
      const data = Object.keys(dataValues).length > 0 ? Object.values(dataValues) : Array(categories.length).fill(0);
      return [{
        name: type.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
        data: categories.length === data.length ? data : Array(categories.length).fill(0),
      }];
    },
    updateChartOptions(): void {
      const categories = this.getXaxisCategories();
      this.smsSentChartOptions = { ...this.smsSentChartOptions, xaxis: { categories } };
      this.smsFailedChartOptions = { ...this.smsFailedChartOptions, xaxis: { categories } };
      this.smsResponseRateChartOptions = { ...this.smsResponseRateChartOptions, xaxis: { categories } };
      this.avgResponseTimeChartOptions = { ...this.avgResponseTimeChartOptions, xaxis: { categories } };
      this.smsOptOutRateChartOptions = { ...this.smsOptOutRateChartOptions, xaxis: { categories } };
    },
  },
});
</script>

<style scoped>
.chart-container {
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chart-title {
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.chart-container:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .chart-container {
    margin: 1rem 0;
  }
}
</style>