<template>
  <div class="mini-server-health-analytics-chart">
    <div class="chart-row">
      <div class="chart-container">
        <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
        <apexchart
          v-else
          ref="cpuChart"
          type="line"
          :options="cpuUsageOptions"
          :series="cpuUsageSeries"
          height="250"
          width="100%"
        ></apexchart>
      </div>
      <div class="chart-container">
        <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
        <apexchart
          v-else
          ref="ramChart"
          type="bar"
          :options="ramMemoryOptions"
          :series="ramMemorySeries"
          height="250"
          width="100%"
        ></apexchart>
      </div>
    </div>
    <div class="chart-row">
      <div class="chart-container">
        <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
        <apexchart
          v-else
          ref="uptimeChart"
          type="radialBar"
          :options="systemUptimeOptions"
          :series="systemUptimeSeries"
          height="250"
          width="100%"
        ></apexchart>
      </div>
      <div class="chart-container">
        <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
        <apexchart
          v-else
          ref="uploadChart"
          type="area"
          :options="uploadSpeedOptions"
          :series="uploadSpeedSeries"
          height="250"
          width="100%"
        ></apexchart>
      </div>
    </div>
    <div class="chart-row">
      <div class="chart-container">
        <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
        <apexchart
          v-else
          ref="downloadChart"
          type="area"
          :options="downloadSpeedOptions"
          :series="downloadSpeedSeries"
          height="250"
          width="100%"
        ></apexchart>
      </div>
      <div class="chart-container">
        <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
        <apexchart
          v-else
          ref="storageChart"
          type="pie"
          :options="storageSpaceOptions"
          :series="storageSpaceSeries"
          height="250"
          width="100%"
        ></apexchart>
      </div>
    </div>
    <div class="chart-row">
      <div class="chart-container">
        <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
        <apexchart
          v-else
          ref="processesChart"
          type="bar"
          :options="runningProcessesOptions"
          :series="runningProcessesSeries"
          height="250"
          width="100%"
        ></apexchart>
      </div>
      <div class="chart-container">
        <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
        <apexchart
          v-else
          ref="spaceChart"
          type="line"
          :options="totalSpaceOptions"
          :series="totalSpaceSeries"
          height="250"
          width="100%"
        ></apexchart>
      </div>
    </div>
    <div class="chart-row">
      <div class="chart-container">
        <div v-if="isLoading" class="text-center text-sm py-2">Loading...</div>
        <apexchart
          v-else
          ref="healthChart"
          type="pie"
          :options="healthOverviewOptions"
          :series="healthOverviewSeries"
          height="250"
          width="100%"
        ></apexchart>
      </div>
      <div class="chart-container empty"></div>
    </div>
  </div>
</template>

<script>
import ApexCharts from 'vue3-apexcharts';

export default {
  name: "MiniServerHealthAnalyticsChart",
  components: {
    apexchart: ApexCharts
  },
  props: {
    duration: {
      type: String,
      required: true,
    },
    info: {
      type: Object,
      default: () => ({
        is_online: false,
        cpuUsagePercentage: '',
        ramMemoryPercentage: '',
        systemUptime: '',
        networkTrafficOutMobile: '',
        networkTrafficInMobile: '',
        numberofrunningprocessor: '',
        totaldiskused: '',
        total_space: '',
        totaldiskusedperct: '',
        boxLocationUpdateServiceStatus: '',
        boxOSVersionUpdateServiceStatus: '',
        captivePortalDbInitServiceStatus: '',
        captivePortalDbSyncServiceStatus: '',
        coovaChilliServiceStatus: '',
        hostapdServiceStatus: '',
        mediaSyncServiceStatus: '',
        ssidServiceStatus: '',
        webminServiceStatus: '',
        motionEyeServiceStatus: '',
      }),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cpuUsageOptions: this.createChartOptions('CPU Usage', '#FF6F6F', 'line'),
      ramMemoryOptions: this.createChartOptions('RAM Memory', '#4EB1A7', 'bar'),
      systemUptimeOptions: this.createChartOptions('System Uptime', '#FFB85C', 'radialBar'),
      uploadSpeedOptions: this.createChartOptions('Upload Speed', '#9B7FCB', 'area'),
      downloadSpeedOptions: this.createChartOptions('Download Speed', '#A4C96F', 'area'),
      storageSpaceOptions: this.createChartOptions('Total Storage Space Used', '#D87A7A', 'pie'),
      runningProcessesOptions: this.createChartOptions('Total Running Processes', '#FF9C58', 'bar'),
      totalSpaceOptions: this.createChartOptions('Total Space', '#4EB1A7', 'line'),
      healthOverviewOptions: this.createChartOptions('Health Service Overview', '#FF6F6F', 'pie'),
      cpuUsageSeries: [],
      ramMemorySeries: [],
      systemUptimeSeries: [],
      uploadSpeedSeries: [],
      downloadSpeedSeries: [],
      storageSpaceSeries: [],
      runningProcessesSeries: [],
      totalSpaceSeries: [],
      healthOverviewSeries: [],
    };
  },
  watch: {
    info: {
      immediate: true,
      deep: true,
      handler() {
        this.updateChartData();
      },
    },
    duration: {
      immediate: true,
      handler() {
        this.updateChartOptions();
      },
    },
    isLoading(newVal) {
      this.updateChartData();
      if (!newVal) {
        this.$nextTick(() => {
          this.refreshCharts();
          console.log('Charts refreshed, container width:', document.querySelector('.mini-server-health-analytics-chart').offsetWidth);
        });
      }
    },
  },
  methods: {
    createChartOptions(title, color, chartType = 'line') {
      return {
        chart: {
          type: chartType,
          animations: {
            enabled: false, // Disable animations to avoid rendering delays
          },
        },
        title: {
          text: title,
        },
        xaxis: {
          categories: this.getXaxisCategories(),
        },
        colors: [color],
        plotOptions: chartType === 'radialBar' ? {
          radialBar: {
            hollow: {
              margin: 5,
            },
            dataLabels: {
              name: { show: true },
              value: {
                show: true,
                formatter: function (val) {
                  return parseFloat(val).toFixed(2);
                },
              },
            },
          },
        } : {},
        labels: chartType === 'pie' ? ['Used', 'Free'] : undefined,
      };
    },
    getXaxisCategories() {
      const categories = [];
      const today = new Date();
      switch (this.duration) {
        case 'today':
          categories.push('Today');
          break;
        case 'week':
          for (let i = 6; i >= 0; i--) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);
            categories.push(this.formatDate(date));
          }
          break;
        case '1month':
          for (let i = 29; i >= 0; i--) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);
            categories.push(this.formatDate(date));
          }
          break;
        case '3months':
          for (let i = 2; i >= 0; i--) {
            const date = new Date(today);
            date.setMonth(today.getMonth() - i);
            categories.push(this.formatMonth(date));
          }
          break;
        case '6months':
          for (let i = 5; i >= 0; i--) {
            const date = new Date(today);
            date.setMonth(today.getMonth() - i);
            categories.push(this.formatMonth(date));
          }
          break;
        case '1year':
          for (let i = 11; i >= 0; i--) {
            const date = new Date(today);
            date.setMonth(today.getMonth() - i);
            categories.push(this.formatMonth(date));
          }
          break;
        case '5years':
          for (let i = 4; i >= 0; i--) {
            const year = today.getFullYear() - i;
            categories.push(year.toString());
          }
          break;
        default:
          return [];
      }
      return categories;
    },
    formatDate(date) {
      return `${date.getDate()}/${date.getMonth() + 1}`;
    },
    formatMonth(date) {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return months[date.getMonth()] + ' ' + date.getFullYear();
    },
    updateChartOptions() {
      const categories = this.getXaxisCategories();
      this.cpuUsageOptions = { ...this.cpuUsageOptions, xaxis: { categories } };
      this.ramMemoryOptions = { ...this.ramMemoryOptions, xaxis: { categories } };
      this.systemUptimeOptions = { ...this.systemUptimeOptions, xaxis: { categories } };
      this.uploadSpeedOptions = { ...this.uploadSpeedOptions, xaxis: { categories } };
      this.downloadSpeedOptions = { ...this.downloadSpeedOptions, xaxis: { categories } };
      this.storageSpaceOptions = { ...this.storageSpaceOptions, xaxis: { categories } };
      this.runningProcessesOptions = { ...this.runningProcessesOptions, xaxis: { categories } };
      this.totalSpaceOptions = { ...this.totalSpaceOptions, xaxis: { categories } };
      this.healthOverviewOptions = { ...this.healthOverviewOptions, xaxis: { categories } };
    },
    updateChartData() {
      const categories = this.getXaxisCategories();
      const len = categories.length;

      this.cpuUsageSeries = [{
        name: 'CPU Usage',
        data: this.isLoading ? Array(len).fill(0) : Array(len).fill(parseFloat(this.info.cpuUsagePercentage) || 0),
      }];
      this.ramMemorySeries = [{
        name: 'RAM Memory',
        data: this.isLoading ? Array(len).fill(0) : Array(len).fill(parseFloat(this.info.ramMemoryPercentage) || 0),
      }];
      this.systemUptimeSeries = this.isLoading ? [0] : [parseFloat(this.info.systemUptime) || 0];
      this.uploadSpeedSeries = [{
        name: 'Upload Speed',
        data: this.isLoading ? Array(len).fill(0) : Array(len).fill(parseFloat(this.info.networkTrafficOutMobile) || 0),
      }];
      this.downloadSpeedSeries = [{
        name: 'Download Speed',
        data: this.isLoading ? Array(len).fill(0) : Array(len).fill(parseFloat(this.info.networkTrafficInMobile) || 0),
      }];
      this.storageSpaceSeries = this.isLoading ? [0, 0] : [
        parseFloat(this.info.totaldiskusedperct) || 0,
        100 - (parseFloat(this.info.totaldiskusedperct) || 0),
      ];
      this.runningProcessesSeries = [{
        name: 'Running Processes',
        data: this.isLoading ? Array(len).fill(0) : Array(len).fill(parseInt(this.info.numberofrunningprocessor) || 0),
      }];
      this.totalSpaceSeries = [{
        name: 'Total Space',
        data: this.isLoading ? Array(len).fill(0) : Array(len).fill(parseFloat(this.info.total_space) || 0),
      }];
      this.healthOverviewSeries = this.isLoading ? [0, 0, 0] : [
        parseFloat(this.info.cpuUsagePercentage) || 0,
        parseFloat(this.info.ramMemoryPercentage) || 0,
        100 - (parseFloat(this.info.cpuUsagePercentage) || 0) - (parseFloat(this.info.ramMemoryPercentage) || 0),
      ];
    },
    refreshCharts() {
      const chartRefs = [
        this.$refs.cpuChart,
        this.$refs.ramChart,
        this.$refs.uptimeChart,
        this.$refs.uploadChart,
        this.$refs.downloadChart,
        this.$refs.storageChart,
        this.$refs.processesChart,
        this.$refs.spaceChart,
        this.$refs.healthChart,
      ];
      chartRefs.forEach((chart, index) => {
        if (chart) {
          const options = [
            this.cpuUsageOptions,
            this.ramMemoryOptions,
            this.systemUptimeOptions,
            this.uploadSpeedOptions,
            this.downloadSpeedOptions,
            this.storageSpaceOptions,
            this.runningProcessesOptions,
            this.totalSpaceOptions,
            this.healthOverviewOptions,
          ][index];
          const series = [
            this.cpuUsageSeries,
            this.ramMemorySeries,
            this.systemUptimeSeries,
            this.uploadSpeedSeries,
            this.downloadSpeedSeries,
            this.storageSpaceSeries,
            this.runningProcessesSeries,
            this.totalSpaceSeries,
            this.healthOverviewSeries,
          ][index];
          chart.updateOptions(options);
          chart.updateSeries(series);
        } else {
          console.warn(`Chart ref at index ${index} is undefined`);
        }
      });
    },
  },
};
</script>

<style scoped>
.mini-server-health-analytics-chart {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem; /* Add padding to prevent edge clipping */
}

.chart-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
  min-width: 0; /* Prevent flex overflow */
}

.chart-container {
  flex: 1 1 0; /* Equal width, no shrinking below content */
  width: 0; /* Allow flex to control width */
  max-width: 450px;
  min-width: 300px; /* Ensure minimum width for visibility */
  background: white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-sizing: border-box;
  overflow: hidden; /* Contain chart overflow */
}

.chart-container.empty {
  flex: 1 1 0;
  width: 0;
  max-width: 450px;
  min-width: 300px;
}

@media (max-width: 767px) {
  .chart-row {
    flex-direction: column;
    gap: 1rem;
  }
  .chart-container {
    max-width: 100%;
    min-width: 0;
    width: 100%;
  }
  .chart-container.empty {
    display: none; /* Hide empty placeholder on small screens */
  }
}
</style>