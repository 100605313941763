<template>
    <div>
      <h2 class="text-xl font-bold mb-4">Queue Insights Overview</h2>
      <div v-if="isLoading" class="text-center">Loading...</div>
      <div v-else class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <!-- Visitors In Chart (Radial Bar) -->
        <div class="chart-container">
          <apexchart
            type="radialBar"
            :options="visitorsInChartOptions"
            :series="visitorsInChartData"
            height="250"
          ></apexchart>
        </div>
  
        <!-- Total Visitors Chart (Area) -->
        <div class="chart-container">
          <apexchart
            type="area"
            :options="totalVisitorsChartOptions"
            :series="totalVisitorsChartData"
            height="250"
          ></apexchart>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ApexCharts from 'vue3-apexcharts';
  
  export default {
    name: "QueueAnalyticsChart",
    components: {
      apexchart: ApexCharts,
    },
    props: {
      queueData: {
        type: Object,
        required: true,
        default: () => ({ visitors_in: [], total_visitors: [] })
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      duration: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        visitorsInChartOptions: this.createChartOptions('Visitors In', '#FF8C8C', 'radialBar'), // Red
        totalVisitorsChartOptions: this.createChartOptions('Total Visitors', '#76C7C0', 'area') // Teal
      };
    },
    computed: {
      chartCategories() {
        const today = new Date();
        const ranges = {
          'today': () => [today.toISOString().split('T')[0]],
          'week': () => {
            const days = [];
            for (let i = 6; i >= 0; i--) {
              const date = new Date(today);
              date.setDate(today.getDate() - i);
              days.push(date.toLocaleString('default', { weekday: 'short' }));
            }
            return days;
          },
          '1month': 1,
          '3months': 3,
          '6months': 6,
          '1year': 12,
          '5years': 60
        };
        const range = ranges[this.duration];
        if (typeof range === 'function') return range();
        const months = range || 6;
        const categories = [];
        for (let i = months - 1; i >= 0; i--) {
          const date = new Date(today);
          date.setMonth(today.getMonth() - i);
          categories.push(date.toLocaleString('default', { month: 'short', year: 'numeric' }));
        }
        return categories;
      },
      visitorsInChartData() {
        // Placeholder: total visitors in as radial bar (set to 0)
        return [0];
      },
      totalVisitorsChartData() {
        const categoriesLength = this.chartCategories.length;
        // Placeholder: all zeros for total visitors
        const data = Array(categoriesLength).fill(0);
        return [{
          name: 'Total Visitors',
          data
        }];
      }
    },
    watch: {
      duration(newDuration) {
        this.updateChartOptions(newDuration);
      },
      queueData(newData) {
        if (newData && !this.isLoading) {
          this.updateChartOptions(this.duration);
        }
      }
    },
    methods: {
      createChartOptions(title, color, type = 'bar') {
        let commonOptions = {
          chart: {
            height: 350,
          },
          title: {
            text: title,
            align: 'left',
          },
          xaxis: {
            categories: this.chartCategories,
          },
          colors: [color],
        };
  
        if (type === 'area') {
          commonOptions.yaxis = {
            labels: {
              formatter: (value) => value.toFixed(0), // Integer counts
            },
            title: { text: 'Visitors' }
          };
          commonOptions.fill = {
            type: 'gradient',
            gradient: { shadeIntensity: 1, opacityFrom: 0.7, opacityTo: 0.3 }
          };
        } else if (type === 'radialBar') {
          commonOptions.plotOptions = {
            radialBar: {
              track: { background: '#e0e0e0', strokeWidth: '100%' },
              dataLabels: {
                name: { fontSize: '18px', color: '#000', fontWeight: 'bold' },
                value: { fontSize: '16px', color: '#000', fontWeight: 'bold' },
              },
            },
          };
          commonOptions.labels = ['Visitors In'];
          delete commonOptions.xaxis;
        }
  
        return commonOptions;
      },
      updateChartOptions(newDuration) {
        const categories = this.chartCategories;
        this.totalVisitorsChartOptions = { ...this.totalVisitorsChartOptions, xaxis: { categories } };
      }
    },
    mounted() {
      this.updateChartOptions(this.duration);
    }
  };
  </script>
  
  <style scoped>
  .chart-container {
    background-color: #ffffff;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .chart-container:hover {
    transform: scale(1.05);
  }
  
  @media (max-width: 768px) {
    .chart-container {
      margin: 1rem 0;
    }
  }
  </style>