<template>
    <div class="container shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] text-6xl rounded-xl p-6 bg-white ">

        <div class="flex justify-between w-full ">
            <h5 style="" class="text-sm pe-4 font-light mt-1 text-[#202224] max-w-[200px]">
                {{ data.topic }}
            </h5>
            <div class="icon-container" :style="{ backgroundColor: data.color }">
                <span class="text-xl"><i :class="data.icon" :style="{ color: data.iconcolor }"></i></span>
            </div>
        </div>
        <div class="figures" v-if="data.figures">
            <span :class="{ 'small-font': data.small }">
                {{ formatNumbers(data.figures) }}
            </span>
        </div>
        <div v-else-if="!data.figures && !data.network && !data.no_zero" class="figures text-lg font-bold ">
            0
        </div>
        <div style="font-size: small;" v-if="data.network" class="count mt-2 font-bold mt-0">
            {{ data.network_figures }}
        </div>

        <small v-if="data.percentage" class="description">
            <span :style="{ color: data.percentage >= 0 ? '#00B69B' : 'red' }">
                <span class="text-xl"> <i :class="data.percentage >= 0 ? 'fa-solid fa-arrow-trend-up' : 'fa-solid fa-arrow-trend-down'"></i></span>
                {{ Math.abs(data.percentage) }}% <span style="color: black;">{{ data.customWord }}</span>
            </span>
        </small>
    </div>
</template>

<script>
import { defineProps } from 'vue';

export default {
  name: 'CardDesign',

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  methods: {
    formatNumbers(num) {
      if (typeof num === 'number' && Number.isInteger(num)) {
        let numStr = num.toString();
        let parts = numStr.split('.');
        let integerPart = parts[0];
        let decimalPart = parts.length > 1 ? '.' + parts[1] : '';
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return integerPart + decimalPart;
      } else {
        return num;
      }
    },
  },
};
</script>

<style scoped>
.container {
    background-color: #ffffff;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height:130px;
}

.topic .icon {
    height: 30px
}

.topic .topic-desc {
    font-weight: light;
    font-size: small;
    color: #202224;
}

.figures {
    font-weight: bolder;
    font-size: x-large;
    position: absolute
}

.icon-container {
    width: 40px;
    height: 40px;
    border-radius: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-container #icon {

    background-color: transparent;
    font-size: 20px;
    margin-right: 5%;
    opacity: 100%
}


.description {
    color: #202224;
    font-size: small;
}

.small-font {
  font-size: 0.5em; /* Adjust font size as desired */
  margin-top:40%
}

.container:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .chart-container {
    margin: 1rem 0;
  }
}
</style>