<template>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
    <div class="chart-container" v-if="!isLoading">
      <apexchart v-if="uptimeChartData.length" :options="uptimeChartOptions" :series="uptimeChartData" type="line" height="350" />
      <div v-else class="text-center text-gray-500">No data available</div>
    </div>
    <div class="chart-container" v-else>
      <div class="text-center text-gray-500">Loading...</div>
    </div>

    <div class="chart-container" v-if="!isLoading">
      <apexchart v-if="transferChartData.length" :options="transferChartOptions" :series="transferChartData" type="area" height="350" />
      <div v-else class="text-center text-gray-500">No data available</div>
    </div>
    <div class="chart-container" v-else>
      <div class="text-center text-gray-500">Loading...</div>
    </div>

    <div class="chart-container" v-if="!isLoading">
      <apexchart v-if="signalStrengthChartData.length" :options="signalStrengthChartOptions" :series="signalStrengthChartData" type="radialBar" height="350" />
      <div v-else class="text-center text-gray-500">No data available</div>
    </div>
    <div class="chart-container" v-else>
      <div class="text-center text-gray-500">Loading...</div>
    </div>
  </div>
</template>

<script>
import ApexCharts from 'vue3-apexcharts';

export default {
  name: "RouterAnalyticsChart",
  components: { apexchart: ApexCharts },
  props: {
    duration: { type: String, required: true },
    metricsData: { type: Object, required: true },
    isLoading: { type: Boolean, default: false }
  },
  data() {
    return {
      uptimeChartOptions: this.createChartOptions('Router Uptime (%)', '#76C7C0', 'line'),
      transferChartOptions: this.createChartOptions('Data Transfer Over Time (MB)', ['#FFC08A', '#FF8C8C'], 'area'), // Two colors
      signalStrengthChartOptions: this.createChartOptions('Signal Strength (%)', '#A5D66A', 'radialBar')
    };
  },
  computed: {
    uptimeChartData() {
      const downloadSpeeds = this.metricsData?.router_speed?.series[0]?.data || [];
      const uptime = downloadSpeeds.map(speed => speed > 0 ? 100 : 0);
      console.log('Uptime Chart Data:', {
        source: 'router_speed.series[0].data',
        rawData: downloadSpeeds,
        processed: uptime,
        series: [{ name: 'Uptime', data: uptime }]
      });
      return [{ name: 'Uptime', data: uptime }];
    },
    transferChartData() {
      const received = this.metricsData?.network_usage?.series[0]?.data || [];
      const transmitted = this.metricsData?.network_usage?.series[1]?.data || [];
      console.log('Transfer Chart Data:', {
        sourceReceived: 'network_usage.series[0].data',
        sourceTransmitted: 'network_usage.series[1].data',
        rawReceived: received,
        rawTransmitted: transmitted,
        series: [
          { name: 'Received', data: received },
          { name: 'Transmitted', data: transmitted }
        ]
      });
      return [
        { name: 'Received', data: received },
        { name: 'Transmitted', data: transmitted }
      ];
    },
    signalStrengthChartData() {
      const strengths = this.metricsData?.router_signal_strength?.series[0]?.data || [];
      const goodCount = strengths.filter(s => s === "strong").length;
      const total = strengths.filter(s => s !== "No data").length;
      const signal = total > 0 ? (goodCount / total * 100) : 0;
      console.log('Signal Strength Chart Data:', {
        source: "router_signal_strength.series[0].data",
        rawData: strengths,
        goodCount: goodCount,
        totalCount: total,
        calculatedPercentage: signal,
        series: [signal]
      });
      return [signal];
    }
  },
  watch: {
    duration: {
      immediate: true,
      handler() {
        this.updateChartOptions();
      }
    },
    metricsData: {
      immediate: true,
      handler(newData) {
        console.log('Metrics Data Updated:', {
          categories: newData?.categories,
          routerSpeed: newData?.router_speed,
          networkUsage: newData?.network_usage,
          signalStrength: newData?.router_signal_strength
        });
        this.updateChartOptions();
      }
    }
  },
  methods: {
    createChartOptions(title, color, chartType = 'bar') {
      const options = {
        chart: { type: chartType, height: 350 },
        title: { text: title, align: 'left' },
        xaxis: { categories: this.getXaxisCategories() },
        colors: Array.isArray(color) ? color : [color], // Handle array or single color
        plotOptions: chartType === 'radialBar' ? {
          radialBar: {
            hollow: { margin: 5 },
            dataLabels: {
              name: { show: true },
              value: { show: true, formatter: (val) => parseFloat(val).toFixed(2) }
            }
          }
        } : {}
      };
      if (chartType === 'radialBar') options.labels = ['Strength'];
      return options;
    },
    getXaxisCategories() {
      return this.metricsData?.categories || [];
    },
    updateChartOptions() {
      const categories = this.getXaxisCategories();
      console.log('Updating Chart Options with Categories:', categories);
      this.uptimeChartOptions = { ...this.uptimeChartOptions, xaxis: { categories } };
      this.transferChartOptions = { ...this.transferChartOptions, xaxis: { categories } };
      this.signalStrengthChartOptions = { ...this.signalStrengthChartOptions, xaxis: { categories: ['Strength'] } };
    }
  }
};
</script>
<style scoped>
.chart-container {
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.chart-container:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .chart-container {
    margin: 1rem 0;
  }
}
</style>
