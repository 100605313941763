<template>
  <template v-if="isLoading">
    <div>
      <content-loader></content-loader>
      <div class="h-[5rem]"></div>
      <content-loader></content-loader>
    </div>
  </template>
  <template v-else>
    <!-- Main layout with sidebar -->
    <div v-if="!isNotShowHeaderAndSidebar" class="flex flex-col" @click="handleCloseModal">
      <DashboardTopNav :isOpenModal="isOpenModal" @onToggle="onToggle" @handleOpenOrCloseModal="handleOpenOrCloseModal" companyLabel="" />

      <div class="bg-dashboardBg flex h-screen overflow-hidden">
        <!-- Sidebar container -->
        <div
          class="lg:w-[130px] lg:flex lg:items-center items-start flex-col lg:pb-14 toggle-sidenav fixed h-full mt-[8vh] bg-white-mobile"
          :class="[isToggle ? 'block' : 'hidden']">
          <span class="my-3 lg:text-center font-semibold text-[0.75rem] font-poppins text-[#888889] tracking-wider bg-white-mobile lg:block hidden">
            Actions
          </span>
          <div
            class="sidebar mb-10 border-r-2 border-sidebarBorder flex flex-col lg:items-center items-start lg:w-full md:w-[150px] w-[150px] overflow-y-auto custom-scrollbar">
            <sidebar-menu
              v-for="route in routes"
              :key="route.id"
              :route-path="route.path"
              :title="route.title"
              :icon="route.icon"
              :sub-menus="route?.subMenus"
              :child-sub-menus="route?.submenus?.childSubMenus"
              :isActive="activeId === route.id"
              @click="handleSetActive(route.id, route.title)"
              classes="mb-2"
            />
          </div>
        </div>

        <!-- Main content container -->
        <div class="lg:ml-[10vw] w-full px-4 lg:px-0 mt-[7vh] overflow-x-hidden">
          <div class="w-full my-3">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>

    <!-- Full-width view for cases where sidebar/header is hidden -->
    <div v-if="isNotShowHeaderAndSidebar" class="w-full lg:px-0 overflow-x-hidden">
      <div class="w-full">
        <router-view></router-view>
      </div>
    </div>
  </template>
</template>

<script >
import moment from 'moment';
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';

import { dashboard_routes } from './helpers/stub_data/dashboard_routes';
import DashboardTopNav from '../src/components/markup/DashboardTopNav.vue';
import {
  routers as _routers,
  dashboardHeadericon,
} from './helpers/stub_data/dashboard_routers';
import SidebarMenu from '@/components/reusable/SidebarMenu.vue';

export default defineComponent({
  name: 'AppPage',
  components: {
    DashboardTopNav,
    SidebarMenu,
  },
  setup() {
    const isLoading = ref(false);
    const isFetchingPackageData = ref(false);
    const isOpenModal = ref(false);
    const isToggle = ref(false);
    const isNotShowHeaderAndSidebar = ref(false);
    const icon = ref({ ...dashboardHeadericon });
    const routes = ref([...dashboard_routes]);
    const routers = ref([..._routers]);
    const activeId = ref(1);
    const activeTab = ref('');

    return {
      isOpenModal,
      icon,
      routes,
      routers,
      activeId,
      activeTab,
      isToggle,
      isNotShowHeaderAndSidebar,
      isLoading,
      isFetchingPackageData,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
    packageData() {
      return this.$store.getters['package'];
    },
    dashboadHeaderIcon() {
      if (this.activeTab == 'Account') {
        return dashboardHeadericon.accountIcon;
      } else if (this.activeTab == 'Routers') {
        return dashboardHeadericon.router;
      } else if (this.activeTab == 'Billing') {
        return dashboardHeadericon.accountIcon;
      } else if (this.activeTab == 'Support') {
        return dashboardHeadericon.accountIcon;
      } else if (this.activeTab == 'Files') {
        return dashboardHeadericon.fileIcon;
      } else if (this.activeTab == 'Captive Portal') {
        return dashboardHeadericon.captiveportal;
      } else if (this.activeTab == 'Dashboard') {
        return dashboardHeadericon.dashboard;
      } else if (this.activeTab == 'Vouchers') {
        return dashboardHeadericon.voucher;
      } else if (this.activeTab == 'Feedback') {
        return dashboardHeadericon.feedback;
      } else {
        return dashboardHeadericon.router;
      }
    },
  },
  beforeMount() {
    this.isAccountModule();
    var checkActiveTab = localStorage.getItem('activeTab');
    var activeId = localStorage.getItem('activeId');
    if (checkActiveTab != null && parseInt) {
      this.activeTab = checkActiveTab;
      this.activeId = activeId != null ? parseInt(activeId) : 0;
    }
  },
  created() {
    if (this.loggedInUser.partner?.plan.name === 'SME') {
      this.routes = this.routes.filter(
        (route) =>
          route.title !== 'Micro Franchisee Management' &&
          route.title !== 'Kiosk Management' &&
          route.title !== 'Officers'
      );
    }

    if (this.loggedInUser.partner?.plan.name === 'Kiosk') {
      this.routes = this.routes.filter(
        (route) =>
          route.title !== 'Router Management' &&
          route.title !== 'Content Management' &&
          route.title !== 'Shiriki Add-On' &&
          route.title !== 'Landing Page Builder' &&
          route.title !== 'Billing' &&
          route.title !== 'SMEs'
      );

    }

    if (localStorage.getItem('partnerId')) {
      this.handleFetchUser();
    }
  },
  mounted() {
    if (this.loggedInUser.partner?.plan.name === 'SME') {
      this.routes = this.routes.filter(
        (route) =>
          route.title !== 'Micro Franchisee Management' &&
          route.title !== 'Kiosk Management' &&
          route.title !== 'Officers'
      );
    }

    if (this.loggedInUser.partner?.plan.name === 'Kiosk') {
      this.routes = this.routes.filter(
        (route) =>
          route.title !== 'Router Management' &&
          route.title !== 'Content Management' &&
          route.title !== 'Shiriki Add-On' &&
          route.title !== 'Landing Page Builder' &&
          route.title !== 'Billing' &&
          route.title !== 'SMEs'
      );

    }
  },
  watch: {
    $route(to, from) {
      this.isAccountModule();
    },
  },
  data() {
    return {
      activebtnId: '',
    };
  },
  methods: {
    async handleFetchPackageData() {
      this.isFetchingPackageData = true;
      const partnerId = localStorage.getItem('partnerId');
      try {
        await this.$store.dispatch('getAllPartnerBillings', partnerId);
        this.isFetchingPackageData = false;
        this.$toast.success('subscriptions retrieved successfully', {
          position: 'top-right',
        });
      } catch (error) {
        this.isFetchingPackageData = false;
        console.log(error);
      }
    },
    async handleFetchUser() {
      this.isLoading = true;
      await this.$store.dispatch('fetchUser');
      this.isLoading = false;
    },
    handleCloseModal() {
      if (this.isOpenModal) {
        this.isOpenModal = false;
      }
    },
    handleOpenOrCloseModal() {
      this.isOpenModal = !this.isOpenModal;
    },
    onToggle() {
      this.isToggle = !this.isToggle;
    },
    handleSetActive(id, title) {
      this.activeId = id;
      localStorage.setItem('activeId', id.toString());
      this.activeTab = title;
      localStorage.setItem('activeTab', title);
    },
    isAccountModule() {
      var hrefPath = document.location.pathname;

      if (
        hrefPath == '/otp' ||
        hrefPath == '/forgot-password' ||
        hrefPath == '/change-password' ||
        hrefPath.includes('/password/reset') ||
        hrefPath == '/signup' ||
        hrefPath == '/'
      ) {
        this.isNotShowHeaderAndSidebar = true;
      } else {
        this.isNotShowHeaderAndSidebar = false;
      }
    },
  },
});
</script>

<style scoped>
/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 1px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #DCE9E3; /* Scrollbar thumb color */
  border-radius: 30px; /* Round edges for scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #4E8D6D; /* Scrollbar track color */
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #e0e0e0; /* Darker green on hover */
}

.custom-scrollbar {
  scrollbar-width: thin; /* For Firefox: thin scrollbar */
  scrollbar-color:#4E8D6D  #e0e0e0; /* Thumb and track color for Firefox */
  overflow-y: hidden; /* Hide scrollbar initially */
  transition: overflow-y 0.3s ease; /* Smooth transition */
  touch-action :scroll;
  overflow-x: hidden;
}
/* Sidebar default styles */

/* Show scrollbar on hover */
.sidebar:hover {
  overflow-y: auto; /* Show scrollbar on hover */
}

/* Custom scrollbar styling */
.sidebar::-webkit-scrollbar {
  width: 8px; /* Adjust scrollbar width */
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #c4c4c4; /* Thumb color */
  border-radius: 4px; /* Rounded corners */
}

.sidebar::-webkit-scrollbar-track {
  background: transparent; /* Transparent track */
}

/* Show scrollbar on hover, focus, or active scroll */
.sidebar:hover::-webkit-scrollbar,
.sidebar:focus-within::-webkit-scrollbar,
.sidebar:active::-webkit-scrollbar {
  display: block; /* Show scrollbar when scrolling */
  width: 8px; /* Adjust width as needed */
}

</style>
